<main>
    <div id="wrap" class="oe_structure oe_empty" data-oe-model="ir.ui.view" data-oe-id="1309" data-oe-field="arch" data-oe-xpath="/t[1]/t[1]/div[1]">
        <section class="s_title parallax s_parallax_is_fixed pt24 pb24" data-vcss="001" data-snippet="s_title" data-scroll-background-ratio="1" data-name="Titre">
            <span class="s_parallax_bg oe_img_bg" style="background-color: rgba(31, 152, 31, 0.15); background-position: 50% 0;"></span>
            <div class="o_we_bg_filter bg-black-0"></div>
            <div class="container">
                <h1><font style="color: rgb(0, 0, 0);">{{"Liste des promotions" | translate}}</font></h1>
            </div>
        </section>
    </div>

    <section class="s_text_block pt40 pb40 o_colored_level" data-snippet="s_text_block" data-name="Texte">
        <div class="s_allow_columns container">
            <div class="row">
                <div class="col-12 mt-4 mt-lg-0 o_colored_level">
                    <!-- Button to create a new one -->
                    <div class="o_list_buttons d-flex justify-content-between mb-3">
                        <button type="button" class="btn btn-primary" routerLink="/promotion">{{'Créer une promotion' | translate}}</button>
                        <button type="button" class="btn btn-secondary" routerLink="/dashboard">{{'Retour' | translate}}</button>
                    </div>
                    <div *ngIf="errorMessage" class="alert alert-danger">
                        {{ errorMessage }}
                    </div>
        
                    <div *ngIf="successMessage" class="alert alert-success">
                        {{ successMessage }}
                    </div>

                    <!-- <div *ngIf="isLoading" class="text-center my-3">
                      <div class="spinner-border text-primary" role="status">
                        <span class="sr-only">Chargement...</span>
                      </div>
                    </div> -->

                    <div class="" tabindex="-1">
                        <table class="o_list_table table table-sm table-hover position-relative mb-0 o_list_table_ungrouped col-12">
                            <thead class="o_list_view_header">
                                <tr class="o_list_view_header_row">
                                    <th class="o_list_view_header_cell o_list_view_header_cell_1" >
                                        <span class="o_list_view_header_cell_text">{{'Nom' | translate}}</span>
                                    </th>
                                    <th class="o_list_view_header_cell o_list_view_header_cell_2">
                                        <span class="o_list_view_header_cell_text">{{'Date de début' | translate}}</span>
                                    </th>
                                    <th class="o_list_view_header_cell o_list_view_header_cell_3" >
                                        <span class="o_list_view_header_cell_text">{{'Date de fin' | translate}}</span>
                                    </th>
                                    <th class="o_list_view_header_cell o_list_view_header_cell_4" >
                                        <span class="o_list_view_header_cell_text">{{'Usage' | translate}}</span>
                                    </th>
                                    <th class="o_list_view_header_cell o_list_view_header_cell_4" >
                                        <span class="o_list_view_header_cell_text">{{'Actions' | translate}}</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody class="o_list_view_body">
                                <tr *ngFor="let promotion of promotions">
                                    <td>{{promotion.title}}</td>
                                    <td>{{promotion.startDate}}</td>
                                    <td>{{promotion.endDate}}</td>
                                    <td>{{promotion.usage}} / {{promotion.limitAmount}}</td>
                                    <td>
                                        <button type="button" class="btn btn-primary" (click)="editPromotion(promotion.id)">
                                            <i class="fa fa-edit"></i>
                                            <!-- {{'Modifier' | translate}} -->
                                        </button>
                                        <button type="button" class="btn btn-danger" (click)="deletePromotion(promotion.id)">
                                            <i class="fa fa-trash"></i>
                                            <!-- {{'Supprimer' | translate}} -->
                                        </button>
                                        <button type="button" class="btn btn-info" (click)="viewPromotion(promotion.id)">
                                            <i class="fa fa-eye"></i>
                                            <!-- {{'Détails' | translate}} -->
                                        </button>
                                        <button *ngIf="(promotion.status == 'active')" type="button" class="btn btn-info" (click)="pausePromotion(promotion.id)">
                                            <i class="fa fa-pause"></i>
                                            <!-- {{'Mettre en pause' | translate}} -->
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>
