// user-profile.component.ts
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../auth.service';
import { UserProfile } from '../models/user-profile';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { environment } from '../environment';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit {
  profileForm: FormGroup;
  userProfile?: UserProfile;
  profilePictureUrl?: string;
  parcels: any[] = [];
  userId?: string; // Store the user's ID
  email?: string | null;
  isLoading = false;
  isSuccess = false;
  errorMessage: string = "";
  phonePrefixes = environment.phonePrefixes;
  

  constructor(
    private fb: FormBuilder, 
    private authService: AuthService, 
    private firestore: AngularFirestore,
    private router: Router) {
    this.profileForm = this.fb.group({
      fullname: [''],
      // lastName: ['', Validators.required],
      email: [''],
      country: [''],
      city: [''],
      // prefix: [''],
      phoneNumber: [''],
      address: [''],
      // zip_code: [''],
      // profilePictureUrl: ['']
    });
  }

  ngOnInit() {
    this.isSuccess = false;
    this.authService.getUser().subscribe(user => {
      if (user) {
        this.authService.getUserProfile(user.uid).then(profile => {
          if (profile) {
            this.userId = user.uid;
            this.email = user.email;
            this.fetchUserParcels();
            console.log("User: ", this.userId);
            this.userProfile = profile;
            this.profileForm.patchValue(profile);
            // this.profilePictureUrl = profile.profilePictureUrl;
          }
        });
      }
    });
  }

  onSubmit() {
    if (this.profileForm.valid) {
      this.isLoading = true;
      const updatedProfile: UserProfile = {
        uid: this.authService.user?.uid || '',
        // email: this.authService.user?.email || '',
        ...this.profileForm.value
      };
      this.authService.saveUserProfile(updatedProfile).then(() => {
        console.log('Profile updated successfully');
        this.isLoading = false;
        this.isSuccess = true;
      }).catch(error => {
        console.error('Error updating profile: ', error);
        this.isLoading = false;
        this.errorMessage = "Impossible de modifier ces informations veuillez contacter BeFret pour signaler le problème";
      });
    }else {
      console.log("Form is not valid");
      this.errorMessage = "Veuillez completer tous les champs obligatoires marqueés d'un *"
    }
  }
  fetchUserParcels() {
    // Fetch parcels where the userId matches the current user's ID
    this.firestore.collection('parcel', ref => ref.where('uid', '==', this.userId))
      .valueChanges()
      .subscribe((data: any[]) => {
        this.parcels = data;
      });

      this.firestore.collection('parcel', ref => ref
        .where('uid', '==', this.userId)
        .orderBy('command_date', 'desc')) // Order by command_date descending
      // .valueChanges({ idField: 'id' })
      .valueChanges()
      .subscribe((data: any[]) => {
        this.parcels = data;
        // this.loading = false;
      });
  }
  viewDetails(trackingID: string): void {
    this.router.navigate(['/details'], { queryParams: { trackingID } });
  }

}
