import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { firstValueFrom } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../auth.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { loadStripe } from '@stripe/stripe-js';
import { UserProfile } from '../models/user-profile';
import { docSnapshots } from '@angular/fire/firestore';
interface ParcelItem { itemDescription: string; numberOfItems: string; itemValue: string; }

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Parcel } from '../models/parcel-model';
import { environment } from '../environment';
@Component({
  selector: 'app-parcel-status',
  templateUrl: './parcel-status.component.html',
  styleUrl: './parcel-status.component.css'
})
export class ParcelStatusComponent implements OnInit {

  parcel : any | null; 
  cost: number = 0.0;
  parcelID: string | null = null;
  userProfile?: UserProfile | null = null;
  mapUrl: SafeResourceUrl | null = null;
  trackingID: string | null = null;
  isLoading = false;
  waitingText: string = "";
  errorMessage = "";
  etiquetteURL = "";
  isDelivering = false;
  isValid = true;
  isOpened = false;
  isOverWeight = false;
  overWeightForm!: FormGroup;
  deliveryForm!: FormGroup;
  phonePrefixes = environment.phonePrefixes; // Assuming you have a list of phone prefixes in your environment file
  isOpenedDRC = false;
  constructor(
    private firestore: AngularFirestore,
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private authService : AuthService,
    private sanitizer: DomSanitizer,
    private fb: FormBuilder,
  ){}
  ngOnInit(): void {
    this.trackingID = this.route.snapshot.queryParamMap.get('trackingID');
    this.parcelID = this.route.snapshot.queryParamMap.get('parcelId');
    console.log('For parcel: ', this.trackingID);
    console.log('For parcel ID: ', this.parcelID);
    console.log("ParcelID to receive: ", this.parcelID);
    // this.user = this.authService.getUser().toPromise();
    this.authService.getUser().subscribe(user => {
      if (user) {
        this.authService.getUserProfile(user.uid).then(profile => {
          this.userProfile = profile;
        });
      }
    });
    this.overWeightForm = this.fb.group({
      weight: ["", Validators.required], });

    this.deliveryForm = this.fb.group({
      receiver_name: ["", Validators.required], 
      receiver_prefix: ["", Validators.required],
      receiver_phone:["", Validators.required,]
    });
    if (this.trackingID) {
      this.firestore.collection('parcel', ref => ref.where('trackingID', '==', this.trackingID))
        .snapshotChanges()
        .subscribe(snapshot => {
          if (snapshot && snapshot.length > 0) {
            const parcelDoc = snapshot[0]; // Get the first matching document
            this.parcel = parcelDoc.payload.doc.data(); // Get the parcel data
            this.parcelID = parcelDoc.payload.doc.id; // Store the document ID in parcelID
            console.log("Parcel: ", this.parcel);
            // if (this.parcel){
            // }else {}
            console.log("Parcel Document ID: ", this.parcelID);
            if (this.parcel){
              this.parcel.items = this.parcel.items.map((item: ParcelItem) => ({ ...item, numberOfItems: parseFloat(item.numberOfItems), itemValue: parseFloat(item.itemValue) }));
              this.etiquetteURL = `/thank-you?parcel=${this.parcelID}&trackingID=${this.trackingID}&notified=true`;
              if (this.parcel.location) {
                const { latitude, longitude } = this.parcel.location;
                console.log("lat: ", latitude, "and lon: ", longitude);
                this.mapUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`https://maps.google.com/maps?q=${latitude},${longitude}&z=12&ie=UTF8&iwloc=&output=embed`);
                console.log("Map URL: ", this.mapUrl);
              } else {
                console.warn("No location data available for this parcel.");
              }
            }
            
          } else {
            console.error("No parcel found with the provided tracking ID.");
          }
        }, error => {
          console.error("Error fetching parcel data: ", error);
        });
    } else if (this.parcelID) {
      this.firestore.doc(`parcel/${this.parcelID}`).get().subscribe(doc => {
        if (doc.exists) {
          this.parcel = doc.data(); // Get the parcel data
          console.log("Arrived Parcel: ", this.parcel);
        if (this.parcel) {
              this.trackingID = this.parcel.trackingID;
              this.etiquetteURL = `/thank-you?parcel=${this.parcelID}&trackingID=${this.trackingID}&notified=true`
              this.parcel.items = this.parcel.items.map((item: ParcelItem) => ({ ...item, numberOfItems: parseFloat(item.numberOfItems), itemValue: parseFloat(item.itemValue) }));
              if (this.parcel.location) {
                const { latitude, longitude } = this.parcel.location;
                console.log("lat: ", latitude, "and lon: ", longitude);
                this.mapUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`https://maps.google.com/maps?q=${latitude},${longitude}&z=12&ie=UTF8&iwloc=&output=embed`);
                console.log("Map URL: ", this.mapUrl);
              }  else {
                console.warn("No location data available for this parcel.");
              }
            }
            
          } else {
            console.error("No parcel found with the provided parcel ID.");
          }
        }, error => {
          console.error("Error fetching parcel data: ", error);
        });
    } else {
      console.error("No tracking ID or parcel ID found in route.");
    }
  }

  // ###### End of Initializations





  // ##### Reception
  receiveAntenne() {
    if (!this.authService.isLoggedIn()){
      console.error("User not authenticated");
      this.router.navigate(['/auth']);
      return;
    }
    if (this.parcelID){
      const parcelRef = this.firestore.collection('parcel').doc(this.parcelID);
      parcelRef.get().toPromise().then((docSnapshots) => {
        const notificationNumber = this.phonePrefixes.find(prefix => prefix.label === this.parcel.phonePrefix2)?.value + this.parcel.sender_phone;
      const date = new Date().toISOString();
        console.log("For the number : ", notificationNumber);
        if (docSnapshots?.exists){
          console.log("Parcel exists, updating...")
          // console.log(docSnapshots);
          this.isLoading = true;
          console.log("Trying update.");
          parcelRef.update({
            status: 'received',
            receive_date: date,
            real_weight: this.parcel.weight,
            display_receive_date: date.split('T')[0],
            receive_agent: this.authService.user?.displayName || this.authService.user?.email || 'Admin',
            receive_agent_uid: this.authService.user?.uid || 'Unknown',
          }).then(() => {
            
            console.log("Trying update..");
            this.sendReceiptNotification(notificationNumber, 
              this.parcelID || 'none', this.parcel.type,
              this.parcel.trackingID, this.parcel.sender_name, this.parcel.city
            );
            console.log("Trying update...");
            console.log('Parcel successfully received');
          }).finally(() => {
            this.router.navigate(['/dashboard']);
          })
          .catch((error) => {
            console.error("Colis ezo kota té parce que : ", error);
            this.errorMessage = `Error receiving parcel: ${error}`;
            this.isLoading = false
          });
        } else {
          console.error("Parcel not found.");        
          this.errorMessage = `Parcel not found`;
        }
      })
    }else {
      console.error("Parcel ID is missing.");
    }
  }
  async sendReceiptNotification(phoneNumber: string, parcelID: string, 
    pType: string, trackingCode: string, sender: string, city: string) {
    try {
      console.log("Trying to send Receipt SMS")
      await firstValueFrom(
        // this.http.post('https://us-central1-befret-development.cloudfunctions.net/sendReceiptNotification',
        this.http.post('https://us-central1-befret-production.cloudfunctions.net/sendReceiptNotification',
          {parcelID: parcelID, phoneNumber: phoneNumber, trackingCode:trackingCode, pType: pType, sender: sender, city: city}
        ));
      console.log("Notifications sent")
      
    } catch(error){
      console.error("Error sending receipt Notification : ", error);
    }
  
  }
  // ##### Expédition 
  expedition(){
    if (!this.authService.isLoggedIn()){
      console.error("User not authenticated");
      this.router.navigate(['/auth']);
      return;
    }
    if (this.parcelID){
      const parcelRef = this.firestore.collection('parcel').doc(this.parcelID);
      parcelRef.get().toPromise().then((docSnapshots) => {
        if (docSnapshots?.exists){
          const notificationNumber = this.phonePrefixes.find(prefix => prefix.value === this.parcel.phonePrefix1)?.value + this.parcel.receiver_phone;
          const senderPhone = this.phonePrefixes.find(prefix => prefix.label === this.parcel.phonePrefix2)?.value + this.parcel.sender_phone;
          console.log("SMS being sent to : ", this.parcel.phonePrefix1);
          console.log("SMS being sent to : ", notificationNumber);
          console.log("And : ", senderPhone);
          const date = new Date().toISOString();
          this.isLoading = true;
          parcelRef.update({
            status: 'sent',
            sent_with: this.authService.user?.displayName || this.authService.user?.email || 'Admin',
            sent_with_uid: this.authService.user?.uid || 'Unknown',
            sent_date: date,
            display_sent_date: date.split('T')[0],
          }).then(() => {
            this.sendExpeditionNotification(
              notificationNumber, 
              this.parcel.city, 
              this.parcel.type, 
              this.parcel.trackingID, 
              this.parcel.sender_name, 
              senderPhone,
              this.parcelID || 'null',
            );
          }).finally(() => {
            this.router.navigate(['/dashboard']);
          }).catch((error) => {
            this.errorMessage = `Error sending parcel: ${error}`;
            this.isLoading = false;
          });
        }
      });
    }
  }
  async sendExpeditionNotification(phoneNumber: string, city: string, pType: string, trackingCode: string, fullname: string, senderPhone: string, parcelID: string){
    try {
      console.log("Invalidation SMS sending");
      // Call the Cloud Function
      // await firstValueFrom(this.http.post('https://us-central1-befret-development.cloudfunctions.net/sendExpeditionNotification',
      await firstValueFrom(this.http.post('https://us-central1-befret-production.cloudfunctions.net/sendExpeditionNotification',
        {city: city, 
        phoneNumber: phoneNumber, 
        trackingCode: trackingCode, 
        pType: pType, 
        sender: fullname, 
        senderPhone: senderPhone,
        parcelID: parcelID}
      ));
      console.log("Expedition SMS sent");
    } catch(error){
      console.error("When sending Expedition SMS: ", error);
    }
  }
  // ##### Arrival
receiveDRC(){
    if (!this.authService.isLoggedIn()){
      console.error("User not authenticated");
      this.router.navigate(['/auth']);
      return;
    }
    if (this.parcelID){
      const parcelRef = this.firestore.collection('parcel').doc(this.parcelID);
        // console.log("Getting parcel : ", parcelRef);
        // console.log("Final parcel : ", this.parcelID);
        parcelRef.get().toPromise().then((docSnapshots) => {
        const notificationNumber = this.phonePrefixes.find(prefix => prefix.label === this.parcel.phonePrefix2)?.value + this.parcel.sender_phone;
        const receiverNumber = this.phonePrefixes.find(prefix => prefix.value === this.parcel.phonePrefix1)?.value + this.parcel.receiver_phone;
        const date = new Date().toISOString();
        console.log("For the number : ", this.parcel.phonePrefix2);
        console.log("For the number : ", notificationNumber);
        console.log("And : ", receiverNumber);
        if (docSnapshots?.exists){
          console.log("Parcel exists, updating...")
          // console.log(docSnapshots);
          this.isLoading = true;
          parcelRef.update({
            status: 'arrived',
            arrived_date: date,
            // real_weight: this.parcel.weight,
            display_arrived_date: date.split('T')[0],
            arrived_agent: this.authService.user?.displayName || this.authService.user?.email || 'Admin',
            arrived_agent_uid: this.authService.user?.uid || 'Unknown',
          }).then(() => {
            this.sendArrivalNotification(notificationNumber, this.parcel.city, this.parcel.type, this.parcel.trackingID,
              this.parcelID || 'null', receiverNumber, this.parcel.pickupMethod, this.parcel.sender_name, this.parcel.receiver_name,
            );
          }).finally(() => {
            this.router.navigate(['/dashboard']);
          })
          .catch((error) => {
            this.errorMessage = `Error receiving parcel: ${error}`;
            this.isLoading = false;
          });
          } else {
            console.error("Parcel not found.");        
            this.errorMessage = `Parcel not found`;
          }
        })
    }
}
  
async sendArrivalNotification(phoneNumber: string, city: string, pType: string, trackingCode: string,
  parcelID: string, receiver_phone: string, pickupMethod: string, sender_name: string, receiver: string
){
  try {
    console.log("Invalidation SMS sending");
    // Call the Cloud Function
    // await firstValueFrom(this.http.post('https://us-central1-befret-development.cloudfunctions.net/sendArrivalNotification',
    await firstValueFrom(this.http.post('https://us-central1-befret-production.cloudfunctions.net/sendArrivalNotification',
      {city: city, 
        phoneNumber: phoneNumber, 
        trackingCode: trackingCode, 
        pType: pType, 
        pickupMethod: pickupMethod,
        parcelID: parcelID, 
        receiverPhone: receiver_phone, 
        sender: sender_name, 
        receiver: receiver
      }
    ));
    console.log("Arrival SMS sent");
  } catch(error){
    console.error("When sending Arrival SMS: ", error);
  }
}
// ##### Delivery
onSubmitDelivery(){
  if (!this.authService.isLoggedIn()){
    console.error("User not authenticated");
    this.router.navigate(['/auth']);
    return;
  }
  if (this.deliveryForm.valid){
    const receiverName = this.deliveryForm.get('receiver_name')?.value;
    const country = this.deliveryForm.get("receiver_prefix")?.value;
    const receiverPhone = country + this.deliveryForm.get("receiver_phone")?.value;
    // const {receiver_name, receiver_prefix, receiver_phone} = this.deliveryForm.value;
    console.log(receiverPhone);
    this.isLoading = true;
    try{
      this.delivered(receiverName, receiverPhone);
    }catch(error){
      console.log("Error delivering: ", error);
      this.errorMessage = "Un problème est survenu. Veuillez contactez le service technique"
    }
  } else {
    console.log('Form is invalid', this.deliveryForm.errors);
    // this.logFormErrors(this.parcelForm);
    this.errorMessage = "Veuillez remplir les champs obligatoires marqués d'un *"
  }
  if (this.parcelID){
    const parcelRef = this.firestore.collection('parcel').doc(this.parcelID);
    parcelRef.get().toPromise().then((docSnapShots) => {
    const notificationNumber = this.phonePrefixes.find(prefix => prefix.label === this.parcel.phonePrefix2)?.value + this.parcel.sender_phone;
    const date = new Date().toISOString();
    
    
  });
  }
}
async sendDeliveryNotification(phoneNumber: string, receiverName: string, pType: string, trackingCode: string,
  parcelID: string, receiver_phone: string, pickupMethod: string, delivery_date: string, city: string, sender:string
){
  try {
    console.log("Delivery SMS sending");
    // await firstValueFrom(this.http.post('https://us-central1-befret-development.cloudfunctions.net/sendDeliveryNotification',
    await firstValueFrom(this.http.post('https://us-central1-befret-production.cloudfunctions.net/sendDeliveryNotification',
      {receiverName: receiverName,
        phoneNumber: phoneNumber,
        trackingCode: trackingCode,
        pType: pType,
        pickupMethod: pickupMethod,
        receiverPhone: receiver_phone,
        parcelID: parcelID,
        delivery_date: delivery_date,
        city: city,
        sender: sender,
      }
    ));
    console.log("Delivery SMS sent");
  } catch(error){
    console.error("When sending Delivery SMS: ", error);
  }
}
delivered(receiverName: string, receiver_phone: string) {
  if (this.parcelID){
    const parcelRef = this.firestore.collection('parcel').doc(this.parcelID);
      // console.log("Getting parcel : ", parcelRef);
      // console.log("Final parcel : ", this.parcelID);
      parcelRef.get().toPromise().then((docSnapshots) => {
      const notificationNumber = this.phonePrefixes.find(prefix => prefix.label === this.parcel.phonePrefix2)?.value + this.parcel.sender_phone;
      // const receiverNumber = this.phonePrefixes.find(prefix => prefix.value === this.parcel.phonePrefix1)?.value + this.parcel.receiver_phone;
      const date = new Date().toISOString();
      console.log("For the number : ", notificationNumber);
      if (docSnapshots?.exists){
        console.log("Parcel exists, updating...")
        // console.log(docSnapshots);
        this.isLoading = true;
        parcelRef.update({
          status: 'delivered',
          delivery_date: date,
          final_receiver_name: receiverName,
          final_receiver_phone: receiver_phone,
          display_delivery_date: date.split('T')[0],
          delivery_agent: this.authService.user?.displayName || this.authService.user?.email || 'Admin',
          delivery_agent_uid: this.authService.user?.uid || 'Unknown'
        }).then(() => {
          this.sendDeliveryNotification(
            notificationNumber,
            receiverName,
            this.parcel.type,
            this.parcel.trackingID,
            this.parcelID || 'null',
            receiver_phone,
            this.parcel.pickupMethod,
            date.split('T')[0],
            this.parcel.city,
            this.parcel.sender_name
          );
        }).finally(() => {
          this.router.navigate(['/dashboard']);
        })
        .catch((error) => {
          this.errorMessage = `Error receiving parcel: ${error}`;
          this.isLoading = false;
        });
        } else {
          console.error("Parcel not found.");        
          this.errorMessage = `Parcel not found`;
        }
      })
  }
}
// ##### OverWeigth
onSubmit() {
  if (!this.authService.isLoggedIn()){
    console.error("User not authenticated");
    this.router.navigate(['/auth']);
    return;
  }
  if (this.parcelID){
    const parcelRef = this.firestore.collection('parcel').doc(this.parcelID);
    parcelRef.get().toPromise().then((docSnapShots) => {
      const notificationNumber = this.phonePrefixes.find(prefix => prefix.label === this.parcel.phonePrefix2)?.value + this.parcel.sender_phone;
      const date = new Date().toISOString();
      if (this.overWeightForm.valid){
        const overWeight = this.overWeightForm.get('weight')?.value;
        if (docSnapShots?.exists){
          this.isLoading = true;
          parcelRef.update({
            status: 'invalid',
            invalidation_reason: 'overWeight',
            invalid_date: date,
            real_weight : this.parcel.weight + overWeight,
            overWeight: overWeight,
            overCost: this.cost,
            displayed_invalid_date: date.split('T')[0],
            invalid_by: this.authService.user?.displayName || this.authService.user?.email || 'Admin',
            invalid_by_uid: this.authService.user?.uid || this.authService.user?.email || 'Unknown'
          }).then(() => {
            console.log("Code to send : ", this.parcel.trackingID);
            this.sendOverWeightNotification(notificationNumber, overWeight, (this.cost).toString(), this.parcel.trackingID, this.parcel.type, this.parcelID || this.parcel.id);
            // Send Notification to sender
          }).finally(() => {
            this.router.navigate(['/dashboard']);
          })
        }
      } else {
        this.errorMessage = "Veuillez inserer un poids";
        console.log("The form is invalid");
      }
      
    });
  }
  
}
async sendOverWeightNotification(phoneNumber: string, overWeight: string, overCost: string, trackingCode: string, pType: string, parcelID: string){
  try {
    console.log("Invalidation SMS sending");
    // Call the Cloud Function
    // await firstValueFrom(this.http.post('https://us-central1-befret-development.cloudfunctions.net/sendOverWeightNotification',
    await firstValueFrom(this.http.post('https://us-central1-befret-production.cloudfunctions.net/sendOverWeightNotification',
      {overWeight: overWeight, phoneNumber: phoneNumber, trackingCode: trackingCode, pType: pType, parcelID: parcelID, overCost: overCost}
    ));
    console.log("Invalidation SMS sent");
  } catch(error){
    console.error("When sending Invalidation SMS: ", error);
  }
}
// ##### Invalidation 
invalidParcel(){
  if (!this.authService.isLoggedIn()){
    console.error("User not authenticated");
    this.router.navigate(['/auth']);
    return;
  }
  if (this.parcelID){
    const parcelRef = this.firestore.collection('parcel').doc(this.parcelID);
    console.log("Getting parcel : ", parcelRef);
    console.log("Invalid parcel : ", this.parcelID);
    parcelRef.get().toPromise().then((docSnapshots) => {
      const notificationNumber = this.phonePrefixes.find(prefix => prefix.label === this.parcel.phonePrefix2)?.value + this.parcel.sender_phone;
      const date = new Date().toISOString();
      if (docSnapshots?.exists){
        this.isLoading = true;
        console.log("Parcel exists");
        
        parcelRef.update({status: 'invalid',
        invalidation_reason: 'conformity',
        invalidation_date: date,
        display_invalidation_date: date.split('T')[0],
        invalidation_agent: this.authService.user?.displayName || this.authService.user?.email || 'Admin',
        invalidation_agent_uid: this.authService.user?.uid || 'Unknown',
        }).then(() => {
          this.sendInvalidationNotification(notificationNumber, this.parcelID || 'none' , this.parcel.type, this.parcel.trackingID, this.parcel.sender_name);
        }).finally(() => {
          this.router.navigate(['/dashboard']);
        })
        .catch((error) => {
          // console.error("Colis ezo kota té parce que : ", error);
          this.errorMessage = `Error invalidating parcel: ${error}`;
          this.isLoading = false;
        });
      } 
    })
  }
}
async sendInvalidationNotification(phoneNumber: string, parcelID: string, pType: string, trackingCode: string, sender: string){
  try {
    console.log("Invalidation SMS sending");
    // Call the Cloud Function
    // await firstValueFrom(this.http.post('https://us-central1-befret-development.cloudfunctions.net/sendInvalidationNotification',
    await firstValueFrom(this.http.post('https://us-central1-befret-production.cloudfunctions.net/sendInvalidationNotification',
      {parcelID: parcelID, phoneNumber: phoneNumber, trackingCode: trackingCode, pType: pType, sender: sender}
    ));
    console.log("Invalidation SMS sent");
  } catch(error){
    console.error("When sending Invalidation SMS: ", error);
  }
}
// Component element
notValid() {
  this.isValid = false;
}
opened() {
  this.isOpened = !this.isOpened;
  }
openedDRC() {
  this.isOpenedDRC = !this.isOpenedDRC;
  }
overWeight(){
  this.isOverWeight = true;
}
openEtiquette(){
  console.log("Trying to go at the url");
  this.router.navigateByUrl(this.etiquetteURL);
}
cancelOverWeigth(){
  this.isOverWeight = false;
}

async deleteParcel() {
  if (!this.authService.isLoggedIn()){
    console.error("User not authenticated");
    this.router.navigate(['/auth']);
    return;
  }
  if (this.parcel.status === 'invalid'){
    const querySnapshot = await this.firestore.collection('parcel', ref => ref.where('trackingID', '==', this.parcel.trackingID)).get().toPromise();
    if (querySnapshot && !querySnapshot.empty) {
        querySnapshot.forEach(async (doc) => {
          this.isLoading = true;
          await this.firestore.doc(`parcel/${doc.id}`).delete();
          console.log("Parcel has been deleted");
          this.router.navigate(['/dashboard']);
        });
      }else {
        this.errorMessage = "Colis non inéxistant ou déjà supprimmé"
      }
  }else {
    this.errorMessage = "Impossible de supprimer un colis valid"
  }
}
startDelivery(){
  this.isDelivering = true;
}
cancelDelivery(){
  this.isDelivering = false;
}
calculateCost(){
  const weight = this.overWeightForm.get("weight")?.value;
  if (this.parcel.pickupMethod === 'warehouse'){
    if (this.parcel.city === "kinshasa"){
      this.cost = 1.0 * weight;
    } else if (this.parcel.city === 'lubumbashi'){
      this.cost = 1.5 * weight;
    }

  }else if (this.parcel.pickupMethod === 'home_delivery'){
    if (this.parcel.city === 'kinshasa'){
      this.cost = 1.5 * weight;
    } else if (this.parcel.city === 'lubumbashi'){
      this.cost = 2.2 * weight;
    }
  }
}

}
