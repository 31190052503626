<main>
    <div class="s_title parallax s_parallax_is_fixed pt24 pb24">
        <span class="s_parallax_bg oe_img_bg" style="background-color: rgba(31, 152, 31, 0.15); background-position: 50% 0;"></span>
        <div class="container">
            <h1>
                <ng-container *ngIf="currentMode === 'create'">Créer une promotion</ng-container>
                <ng-container *ngIf="currentMode === 'edit'">Modifier la promotion</ng-container>
                <ng-container *ngIf="currentMode === 'details'">Détails de la promotion</ng-container>
            </h1>
        </div>
    </div>

    <section class="s_text_block pt40 pb40">
        <div class="container">
            <!-- <div *ngIf="isLoading" class="text-center">
                <div class="spinner-border text-primary" role="status">
                    <span class="sr-only">Chargement...</span>
                </div>
            </div> -->

            <div *ngIf="errorMessage" class="alert alert-danger">
                {{ errorMessage }}
            </div>

            <div *ngIf="successMessage" class="alert alert-success">
                {{ successMessage }}
            </div>

            <form [formGroup]="promotionForm" (ngSubmit)="savePromotion()">
                <!-- Basic Information -->
                <div class="card mb-4">
                    <div class="card-header">
                        <h5>Informations générales</h5>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-6 mb-3">
                                <label for="title">Titre*</label>
                                <input type="text" id="title" class="form-control" formControlName="title">
                                <div *ngIf="promotionForm.get('title')?.invalid && promotionForm.get('title')?.touched" class="text-danger">
                                    Le titre est requis
                                </div>
                            </div>

                            <div class="col-md-6 mb-3">
                                <label for="type">Type de promotion*</label>
                                <select id="type" class="form-control" formControlName="type">
                                    <option *ngFor="let type of promotionTypes" [value]="type.value">{{ type.label }}</option>
                                </select>
                            </div>
                        </div>

                        <div class="row" *ngIf="promotionForm.get('type')?.value === 'code'">
                            <div class="col-md-6 mb-3">
                                <label for="code">Code promo*</label>
                                <div class="input-group">
                                    <input type="text" id="code" class="form-control" formControlName="code" 
                                        [attr.placeholder]="promotionForm.get('type')?.value === 'code' ? 'Saisir le code' : 'Code automatique'">
                                    <div class="input-group-append" *ngIf="currentMode !== 'details'">
                                        <button type="button" class="btn btn-outline-secondary" (click)="generateRandomCode()">Générer</button>
                                    </div>
                                </div>
                                <div *ngIf="promotionForm.get('code')?.invalid && promotionForm.get('code')?.touched" class="text-danger">
                                    Le code doit contenir 8 caractères
                                </div>
                            </div>

                            <!-- <div class="col-md-6 mb-3">
                                <label for="status">Statut*</label>
                                <select id="status" class="form-control" formControlName="status">
                                    <option *ngFor="let option of statusOptions" [value]="option.value">{{ option.label }}</option>
                                </select>
                            </div> -->
                        </div>

                        <div class="form-group mb-3">
                            <label for="description">Description*</label>
                            <textarea id="description" class="form-control" rows="3" formControlName="description"></textarea>
                            <div *ngIf="promotionForm.get('description')?.invalid && promotionForm.get('description')?.touched" class="text-danger">
                                La description est requise
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Discount Details -->
                <div class="card mb-4">
                    <div class="card-header">
                        <h5>Détails de la promotion</h5>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-6 mb-3">
                                <label for="typeOfComputing">Type de calcul*</label>
                                <select id="typeOfComputing" class="form-control" formControlName="typeOfComputing">
                                    <option *ngFor="let compType of computingTypes" [value]="compType.value">{{ compType.label }}</option>
                                </select>
                            </div>
                            
                            <div class="col-md-6 mb-3">
                                <label *ngIf="promotionForm.get('typeOfComputing')?.value === 'percentage'" for="amount">Pourcentage*</label>
                                <label *ngIf="promotionForm.get('typeOfComputing')?.value === 'fixed'" for="amount">Montant*</label>
                                <label *ngIf="promotionForm.get('typeOfComputing')?.value === 'gift'" for="gift_name">Nom du cadeau*</label>
                                <div class="input-group">
                                    <input type="number" id="amount" class="form-control" formControlName="amount" min="0" *ngIf="promotionForm.get('typeOfComputing')?.value !== 'gift'">
                                    <input type="text" id="gift_name" class="form-control" formControlName="gift_name" *ngIf="promotionForm.get('typeOfComputing')?.value === 'gift'">
                                    <div class="input-group-append" *ngIf="promotionForm.get('typeOfComputing')?.value === 'percentage'">
                                        <!-- <label for="amount">Pourcentage*</label> -->
                                        <span class="input-group-text">%</span>
                                    </div>
                                    <div class="input-group-append" *ngIf="promotionForm.get('typeOfComputing')?.value === 'fixed'">
                                        <!-- <label for="amount">Montant/Pourcentage*</label> -->
                                        <span class="input-group-text">€</span>
                                    </div>
                                </div>
                                <div *ngIf="promotionForm.get('amount')?.invalid && promotionForm.get('amount')?.touched" class="text-danger">
                                    Montant invalide
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Validity Period -->
                <div class="card mb-4">
                    <div class="card-header">
                        <h5>Période de validité</h5>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-6 mb-3">
                                <label for="startDate">Date de début*</label>
                                <input type="date" id="startDate" class="form-control" formControlName="startDate">
                                <div *ngIf="promotionForm.get('startDate')?.invalid && promotionForm.get('startDate')?.touched" class="text-danger">
                                    Date de début requise
                                </div>
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="endDate">Date de fin*</label>
                                <input type="date" id="endDate" class="form-control" formControlName="endDate">
                                <div *ngIf="promotionForm.get('endDate')?.invalid && promotionForm.get('endDate')?.touched" class="text-danger">
                                    Date de fin requise
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Restrictions -->
                <div class="card mb-4">
                    <div class="card-header">
                        <h5>Conditions d'application</h5>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="form-check mb-3">
                                <input type="checkbox" id="hasAmountTranche" class="form-check-input" formControlName="hasAmountTranche">
                                <label class="form-check-label" for="hasAmountTranche">Tranche de coût</label>
                            </div>
                        </div>
                        <div class="row" *ngIf="promotionForm.get('hasAmountTranche')?.value">
                            <div class="col-md-6 mb-3">
                                <label for="minAmount">Montant minimum (€)</label>
                                <input type="number" id="minAmount" class="form-control" formControlName="minAmount" min="0" step="0.01">
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="maxAmount">Montant maximum (€)</label>
                                <input type="number" id="maxAmount" class="form-control" formControlName="maxAmount" min="0" step="0.01">
                            </div>
                        </div>
                        
                        <div class="row">
                            <div class="form-check mb-3">
                                <input type="checkbox" id="hasWeightTranche" class="form-check-input" formControlName="hasWeightTranche">
                                <label class="form-check-label" for="hasWeightTranche">Tranche de poids</label>
                            </div>
                        </div>
                        <div class="row" *ngIf="promotionForm.get('hasWeightTranche')?.value">
                            <div class="col-md-6 mb-3">
                                <label for="minWeight">Poids minimum (kg)</label>
                                <input type="number" id="minWeight" class="form-control" formControlName="minWeight" min="0" step="0.1">
                            </div>
                            <div class="col-md-6 mb-3">
                                <label for="maxWeight">Poids maximum (kg)</label>
                                <input type="number" id="maxWeight" class="form-control" formControlName="maxWeight" min="0" step="0.1">
                            </div>
                        </div>

                        <div class="form-check mb-3">
                            <input type="checkbox" id="limit" class="form-check-input" formControlName="limit">
                            <label class="form-check-label" for="limit">Limiter le nombre d'utilisations</label>
                        </div>

                        <div class="mb-3" *ngIf="promotionForm.get('limit')?.value">
                            <label for="limitAmount">Nombre maximum d'utilisations</label>
                            <input type="number" id="limitAmount" class="form-control" formControlName="limitAmount" min="1">
                            <div *ngIf="promotionForm.get('limitAmount')?.invalid && promotionForm.get('limitAmount')?.touched" class="text-danger">
                                La limite doit être supérieure à 0
                            </div>
                        </div>

                        <div class="form-check mb-3">
                            <input type="checkbox" id="cumulable" class="form-check-input" formControlName="cumulable">
                            <label class="form-check-label" for="cumulable">Cumulable avec d'autres promotions</label>
                        </div>

                        <div class="mb-4">
                            <label>Pays autorisés</label>
                            <div class="row">
                                <ng-container formArrayName="countiesAllowed">
                                    <div class="col-md-4 mb-2" *ngFor="let country of europeanCountries; let i = index">
                                        <div class="form-check">
                                            <input type="checkbox" [formControlName]="i" class="form-check-input" [id]="'country_' + i">
                                            <label class="form-check-label" [for]="'country_' + i">{{ country.label }}</label>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Action Buttons -->
                <div class="d-flex justify-content-between mt-4">
                    <button type="button" class="btn btn-secondary" (click)="cancel()">Annuler</button>
                    
                    <div>
                        <button *ngIf="currentMode === 'details'" type="button" class="btn btn-primary me-2" (click)="switchToEditMode()">Modifier</button>
                        <button *ngIf="currentMode === 'details'" type="button" class="btn btn-danger me-2" (click)="deletePromotion()">Supprimer</button>
                        
                        <button *ngIf="currentMode === 'edit' || currentMode === 'create'" type="submit" class="btn btn-success" [disabled]="isLoading">
                            <span *ngIf="isLoading" class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                            {{ currentMode === 'create' ? 'Créer' : 'Enregistrer' }}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </section>
</main>