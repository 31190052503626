<main>
    <div class="">
        <div class="">
            <div class="">
                <div class="">
                    
            <div class="row col-md-12">
                    
            <div class="row col-md-12" style="padding: 10px; marker-start: 10px;"></div>
                <div class="col-md-6" >
                    <h3>Revenus par pays</h3>
                    <table class="o_list_table table table-sm table-hover position-relative mb-0 o_list_table_ungrouped" *ngIf="userProfile?.ability === 'topManager' || userProfile?.ability === 'manager'" style="padding: 2px;">
                        <thead>
                        </thead>
                            <th>
                                Paiements
                            </th>
                            <th>
                                Pays
                            </th>
                            <th class="text-right">
                                Commandes
                            </th>
                            <th class="text-right">
                                % (€)
                            </th>
                        <tbody>
                            <tr *ngFor="let country of countrySummary; let i = index" (click)="countryList(country.name)"
                                
                            >
                                <td>€{{country.totalCost}}</td>
                                <td class="country-cell">
                                    {{country.name}}
                                    <div class="country-bar color-{{i % 5}}" [style.width]="(((country.totalCost * 100) / totalCost).toFixed(2)) + '%'"></div>
                                </td>
                                <td class="text-right">{{country.commandCount}}</td>
                                <td class="text-right"> <p class="f-color-{{i % 5}}">{{(((country.totalCost * 100) / totalCost).toFixed(2))}}%</p></td>
                                <!-- <td class="text-right">{{Math.round((country.commandCount * 100) / totalCount)}}</td> -->
                            </tr>
                            
                        </tbody>
                    </table>
                    <div>
                        <br>    
                    <h5>{{'Générales' | translate}}</h5>                
                    <button class="btn btn-primary" style="width: 50%;" (click)="parcelList()">Liste des colis</button>
                    <button class="btn btn-primary" style="width: 50%;" (click)="parcelScan()">Scanner un colis</button>
                    <hr style="border: 0.3px solid #00000078;">
                    <h5>{{'Belgique' | translate}}</h5> 
                    <button class="btn btn-primary" style="width: 50%;"  (click)="pendingList('pending')">Reception BEL : {{pendingCount}}</button>
                    <button class="btn btn-primary" style="width: 50%;"  (click)="receivedList('received')">Envoi RDC : {{receivedCount}}</button>
                    <hr style="border: 0.3px solid #00000078;">
                    <h5>{{'RDC' | translate}}</h5> 
                    <button class="btn btn-primary" style="width: 50%;"  (click)="arrivedList('arrived')">Livraisons : {{arrivedCount}}</button>
                    <button class="btn btn-primary" style="width: 50%;" (click)="sentList('sent')">ReceptionRDC : {{sentCount}}</button>
                    <hr style="border: 0.3px solid #00000078;">
                    <h5>{{'Management' | translate}}</h5> 
                    <button class="btn btn-primary" style="width: 50%;" routerLink="#">Gestions d'accès</button>
                    <button class="btn btn-primary" style="width: 50%;"  routerLink="/promotions-list">Les promotions</button>
                    <!-- Add a separator line -->
                    
                    
                    </div>
                    
                </div>

                <div class="col-md-6">
                    <h3>Revenus mensuels</h3>
                      <div class="monthly-chart">
                        <div class="chart-container">
                          <!-- Scale markers -->
                          <div class="scale-markers">
                            <div class="scale-marker" *ngFor="let marker of scaleMarkers; let i = index" 
                                 [style.left]="((i + 1) * 25) + '%'">
                              <div class="marker-line"></div>
                              <span class="marker-value">€{{ marker }}</span>
                            </div>
                          </div>
                          
                          <!-- Bars -->
                          <div class="monthly-bars">
                            <div class="monthly-bar-row" *ngFor="let month of monthlyData; let i = index">
                              <div class="month-label">{{ month.label }}</div>
                              <div class="bar-container">
                                <div class="monthly-bar" 
                                     [ngStyle]="{
                                       'background': getBarGradient(i, monthlyData.length),
                                       '--final-width': month.percentage + '%'
                                     }"
                                     [class.empty-bar]="month.totalCost === 0"
                                     [style.animation-delay]="'0s, ' + (0.5 + i * 0.1) + 's'">
                                  <span class="bar-value" [style.animation-delay]="(1.3 + i * 0.1) + 's'">
                                    €{{ month.totalCost.toFixed(2) }}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                </div>
                
            </div>
        </div>
              
            </div>
          </div>
        </div>




<!-- 
<h2>Ce mois-ci</h2>
 <div class="row">
    <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="card card-stats" (click)="mailList('Courrier')">
            <div class="card-header card-header-warning card-header-icon">
                <div class="card-icon">
                    <i class="material-icons">mail</i>
                </div>
                <p class="card-category">Couriers : {{courierCount}}</p>
                <h3 class="card-title">€{{courierTotalCost.toFixed(2)}}</h3>
            </div>
            <div class="card-footer">
            </div>
        </div>
    </div>



    <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="card card-stats" (click)="packageList('Paquet')">
            <div class="card-header card-header-rose card-header-icon">
                <div class="card-icon">
                    <i class="material-icons">inventory</i>
                </div>
                <p class="card-category">Paquets : {{packageCount}}</p>
                <h3 class="card-title">€{{packageTotalCost.toFixed(2)}}</h3>
            </div>
            <div class="card-footer">
            </div>
        </div>
    </div>




    <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="card card-stats" (click)="parcelList()">
            <div class="card-header card-header-success card-header-icon">
                <div class="card-icon">
                    <i class="material-icons">store</i>
                </div>
                <p class="card-category">Commandes : {{totalCount}}</p>
                <h3 class="card-title">€{{totalCost.toFixed(2)}}</h3>
            </div>
            <div class="card-footer">
            </div>
        </div>
    </div>
    
    
    
    
    
    <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="card card-stats" (click)="deliveredList('delivered')">
            <div class="card-header card-header-info card-header-icon">
                <div class="card-icon">
                    <i class="fa fa-truck"></i>
                </div>
                <p class="card-category">Livraisons : {{deliveredCount}}</p>
                <h3 class="card-title">€{{deliveredTotalCost.toFixed(2)}}</h3>
            </div>
            <div class="card-footer">
            </div>
        </div>
    </div> 
</div>-->
<br>
             


</main>
