import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AuthService } from '../auth.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-parcel-list',
  templateUrl: './parcel-list.component.html',
  styleUrl: './parcel-list.component.css'
})
export class ParcelListComponent implements OnInit{
  parcels: any[] = [];
  notDisplayedParcels: any[] = []; // Add this property
  userId?: string;
  country: string | null = null;
  delivered: string | null = null;
  arrived: string | null = null;
  pending: string | null = null;
  received: string | null = null;
  sent: string | null = null;
  paquet: string | null = null;
  mail: string | null = null;
  constructor(
    private firestore: AngularFirestore,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
  ){}
ngOnInit(): void {
  this.country = this.route.snapshot.queryParamMap.get('country');
  this.delivered = this.route.snapshot.queryParamMap.get('delivered');
  this.arrived = this.route.snapshot.queryParamMap.get('arrived');
  this.pending = this.route.snapshot.queryParamMap.get('pending');
  // this.received = this.route.snapshot.queryParamMap.get('received');
  this.sent = this.route.snapshot.queryParamMap.get('sent');
  this.received = this.route.snapshot.queryParamMap.get('received');
  this.paquet = this.route.snapshot.queryParamMap.get('paquet');
  this.mail = this.route.snapshot.queryParamMap.get('mail');
  
  this.authService.getUser().subscribe(user => {
    if (user) {
      // user must isEmployee == 'true' and ability == 'manager'
      this.userId = user.uid;

      if (this.country){
        this.fetchCountryParcel(this.country);
      } 
      else if (this.delivered){
        this.fetchDeliveredParcel(this.delivered);
      } 
      else if (this.pending){
        this.fetchPendingParcel(this.pending);
      } 
      else if (this.sent){
        this.fetchSentParcel(this.sent);
      } 
      else if (this.arrived){
        this.fetchArrivedParcel(this.arrived);
      } 
      else if (this.received){
        this.fetchReceivedParcel(this.received);
      } 
      else if (this.paquet){
        this.fetchPaquetParcel(this.paquet);
      }else if (this.mail){
        this.fetchMailParcel(this.mail);
      }
      else {
        this.fetchParcels();
      }
      console.log("User: ", this.userId);
      console.log("Stored parcels: ", this.parcels);
    }
  });
}
  fetchReceivedParcel(received: string | null) {
    this.firestore.collection('parcel', ref => ref.where('status', '==', received))
  .valueChanges()
  .subscribe((data: any[]) => {
    this.parcels = data;
  });
  }
  fetchArrivedParcel(arrived: string) {
    this.firestore.collection('parcel', ref => ref.where('status', '==', arrived))
  .valueChanges()
  .subscribe((data: any[]) => {
    this.parcels = data;
  });
  }
  fetchSentParcel(sent: string) {
    this.firestore.collection('parcel', ref => ref.where('status', '==', sent))
  .valueChanges()
  .subscribe((data: any[]) => {
    this.parcels = data;
  });
  }
  fetchPendingParcel(pending: string | null) {
    this.firestore.collection('parcel', ref => ref.where('status', '==', pending))
  .valueChanges()
  .subscribe((data: any[]) => {
    this.parcels = data;
  });
  }

fetchCountryParcel(countryID: string){
  if (countryID == 'Autres'){
    this.fetchParcels();
  } else {
    this.firestore.collection('parcel', ref => ref.where('status', '!=', 'draft').where('phonePrefix2', '==', countryID))
      .valueChanges()
      .subscribe((data: any[]) => {
        console.log(`Country parcels (${countryID}) from Firestore:`, data);
        this.notDisplayedParcels = data;
        
        // Sort by create_date in descending order
        this.parcels = [...data].sort((a, b) => {
          if (a.create_date && b.create_date) {
            return new Date(b.create_date).getTime() - new Date(a.create_date).getTime();
          }
          return 0;
        });
        
        console.log("Sorted country parcels:", this.parcels);
      });
  }
}

fetchMailParcel(mail: string){
  this.firestore.collection('parcel', ref => 
    // ref.where('status', '!=', 'draft').where('type', '==', mail))
    ref.where('type', '==', mail).where('status', '!=', 'draft'))
  .valueChanges()
  .subscribe((data: any[]) => {
    this.parcels = data;
  });
}
fetchPaquetParcel(paquet: string){
  // this.firestore.collection('parcel', ref => ref.where('status', '!=', 'draft').where('type', '==', paquet))
  this.firestore.collection('parcel', ref => ref.where('type', '==', paquet).where('status', '!=', 'draft'))
  .valueChanges()
  .subscribe((data: any[]) => {
    this.parcels = data;
  });
}
fetchDeliveredParcel(delivered: string){
  this.firestore.collection('parcel', ref => ref.where('status', '==', delivered))
  .valueChanges()
  .subscribe((data: any[]) => {
    this.parcels = data;
  });
}

fetchParcels() {
  // Fetch parcels where the userId matches the current user's ID
  this.firestore.collection('parcel', ref => ref.where('status', '!=', "draft"))
    .valueChanges()
    .subscribe((data: any[]) => {
      console.log("Original data from Firestore:", data);
      this.notDisplayedParcels = data;
      
      // Sort by create_date in descending order (newest first)
      this.parcels = [...data].sort((a, b) => {
        if (a.create_date && b.create_date) {
          console.log(`Comparing dates: ${a.create_date} vs ${b.create_date}`);
          return new Date(b.create_date).getTime() - new Date(a.create_date).getTime();
        }
        return 0;
      });
      
      console.log("Sorted parcels:", this.parcels);
    });
}

viewDetails(trackingID: any) {
  this.router.navigate(['/status'], { queryParams: { trackingID } });
}

}
