import { Component, OnInit, HostListener } from '@angular/core';
import { AuthService } from '../auth.service';
import { UserProfile } from '../models/user-profile';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import firebase from 'firebase/compat/app';
import { translate } from 'pdf-lib';
import { environment } from '../environment';
@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css']
})
export class AuthComponent implements OnInit {
  userProfile?: UserProfile | null = null;
  recaptchaVerifier: firebase.auth.RecaptchaVerifier | undefined;
  confirmationResult: firebase.auth.ConfirmationResult | undefined;
  isMobile: boolean = false;
  authForm!: FormGroup;
  isLoginMode = true;
  isByPhone = false;
  credentialCorect = true;
  errorPassword = "";
  returnUrl!: string;
  
  code: string = '';
  waitingCode: boolean = false;
  phoneNumber: string = '';

  countries: { [key: string]: string[] } = {};
  cities: string[] = [];
  selectedFile: File | null = null;
  profilePicturePreview: string | ArrayBuffer | null = null;
  phonePrefixes = environment.phonePrefixes;
  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder, 
    private af: AuthService, 
    private router: Router) {}

  ngOnInit(): void {
    this.checkScreenSize();
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    this.authForm = this.fb.group({
      email: ['', [Validators.email]], // Wrap validators in an array
      password: ['', [Validators.minLength(6)]],
      password2: [''],
      phoneNumber: [''],
      phonePrefix: [""],
      fullname: [''],
      code: [''],
    });
    

  }
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenSize();
  }

  checkScreenSize() {
    this.isMobile = window.innerWidth <= 768;
  }
  startPhoneNumberVerification() {
    const prefix = this.authForm.get("phonePrefix")?.value;
    const num = this.authForm.get("phoneNumber")?.value;
    this.phoneNumber = prefix + num;
    
    console.log('Phone number: ', this.phoneNumber);
    try{
      const appVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
        size: 'small', // or 'normal' for visible reCAPTCHA
        callback: (response : any) => {
          // Handle reCAPTCHA response (optional)
          console.log("Recaptcha response: ", response);
        },
      });
      
      console.log("Next, login : ", appVerifier);
      this.af.loginWithPhoneNumber(this.phoneNumber, appVerifier)
        .then(result => {
          this.confirmationResult = result;
          // You can navigate to the verification page or handle it as needed.
          console.log(this.confirmationResult);
          console.log('SMS sent successfully.');
        })
        .catch(error => {
          console.error('Error sending SMS:', error);
        });
    }catch(error){
      console.error("New start error: ", error);
    }
    this.waitingCode = !this.waitingCode;
    
  }

  verifyPhoneNumber() {
    if (!this.confirmationResult) {
      console.error('No confirmation result available');
      return;
    }
    this.code = this.authForm.get("code")?.value;
    this.confirmationResult.confirm(this.code)
      .then(result => {
        
        // User successfully signed in. Access the user object if needed.
        const user = result.user;
        console.log('User signed in successfully.');
        this.router.navigateByUrl("/");
      })
      .catch(error => {
        console.error('Verification code is incorrect', error);
      });
  }
  
  async onSubmit() {
    if (this.isLoginMode){
      if (this.isByPhone){
        console.log("try log by phone")
        this.errorPassword = "L'authentification par téléphone n'est pas encore disponible"
        // const code = this.authForm.get("code")?.value;
        // this.af.verifyPhoneNumber(code, this.verificationId).then(()=>{
        //   console.log("Phone number verified succesfuly");
        // }).catch(error => {
        //   console.error("Error during verification code: ", error);
        // });
      }
      // Connexion
      else {
        if (this.authForm.valid){
          const login = this.authForm.get("email")?.value;
          const password = this.authForm.get("password")?.value;
          console.log("AuthForm Valid");
          try{
            const result = this.af.login(login, password);
            console.log("result: ", result)
            const user = (await result).user;
              if (!this.af.isLoggedIn){
                this.credentialCorect = !this.credentialCorect;
              }
              // Get return URL and restore flag from query parameters
              const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
              const restore = this.route.snapshot.queryParams['restore'] || null;

              // Redirect to return URL with restore flag if present
              if (restore) {
                this.router.navigate([returnUrl], { queryParams: { restore: true } });
              } else {
                this.router.navigateByUrl(returnUrl);
              }
            console.log("The result of login :",user);
          }catch(error){
            console.error(error);
            this.credentialCorect = !this.credentialCorect;
          }
          //this.router.navigateByUrl("/");
        }else {
          console.log("Not AuthForm Valid");

        }
      }
    // Inscription
    }else if (!this.isLoginMode){
      const fullname = this.authForm.get("fullname")?.value;
      const email = this.authForm.get("email")?.value;
      const password = this.authForm.get("password")?.value;
      const password2 = this.authForm.get("password2")?.value;
      if (this.authForm.valid){
        console.log("AuthForm Valid");
        if (password === password2){
          console.log("Passwords match");
            try{
              const result = await this.af.signUp(email, password, fullname);
              console.log("Sign Up :", result);
              // Get return URL and restore flag from query parameters
              const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
              const restore = this.route.snapshot.queryParams['restore'] || null;

              // Redirect to return URL with restore flag if present
              if (restore) {
                this.router.navigate([returnUrl], { queryParams: { restore: true } });
              } else {
                this.router.navigateByUrl(returnUrl);
              }
              if (!this.af.isLoggedIn){
                this.errorPassword = "Please fill all required field";
              }
            }
            catch(error){
              console.error(error);
            }
        }else {
          console.log("Password didn't match");
          this.errorPassword = "Le mot de passe ne correspond pas"
        }
      } else {
        if (!(this.authForm.get("password")?.valid)){
          this.errorPassword = "Le mot de passe doit contenir au-moins 6 caractères";
        } else if(!(this.authForm.get("email")?.valid)){
          this.errorPassword = "Adresse mail invalid";
        }
        else {
          this.errorPassword = "Veuillez remplir toutes les informations demandées";
        }
      }   
    }
    console.log("Submitted");
    // const { email, password, phoneNumber, firstName, lastName, country, city, address, zip_code } = this.authForm.value;
    // if (this.isLoginMode) {
    //   if (this.authForm.valid) {
    //     this.af.login(email, password)
    //       .then(() => {
    //         this.router.navigate(['description']);
    //       }).catch(error => console.error("Auth error by mail: ", error));
    //   } 
    // } else {
    //   const formValues = this.authForm.value;
    //   if (this.selectedFile) {
    //     const storage = getStorage();
    //     const filePath = `profile_pictures/${formValues.email}_${Date.now()}`;
    //     const storageRef = ref(storage, filePath);
    //     const uploadTask = uploadBytesResumable(storageRef, this.selectedFile);
    //     uploadTask.on('state_changed',
    //       (snapshot) => {},
    //       (error) => {
    //         console.error("Error uploading file:", error);
    //       },
    //       async () => {
    //         const url = await getDownloadURL(uploadTask.snapshot.ref);
    //         await this.af.signUp(
    //           formValues.email,
    //           formValues.password,
    //           formValues.phoneNumber,
    //           formValues.firstName,
    //           formValues.lastName,
    //           formValues.country,
    //           formValues.city,
    //           formValues.address,
    //           formValues.zipCode,
    //         );
    //       }
    //     );
    //   } else {
    //     await this.af.signUp(
    //       formValues.email,
    //       formValues.password,
    //       formValues.phoneNumber,
    //       formValues.firstName,
    //       formValues.lastName,
    //       formValues.country,
    //       formValues.city,
    //       formValues.address,
    //       formValues.zipCode,
    //     );
    //   }
    // }
  }

  async onGoogleLogin() {
    await this.af.googleLogin();
    // Get return URL and restore flag from query parameters
    const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    const restore = this.route.snapshot.queryParams['restore'] || null;

    // Redirect to return URL with restore flag if present
    if (restore) {
      this.router.navigate([returnUrl], { queryParams: { restore: true } });
    } else {
      this.router.navigateByUrl(returnUrl);
    }
    // this.af.googleLogin().then(() => {this.router.navigate([this.returnUrl,]) || '/';
    // }).catch(error => console.error(error));


    // this.af.googleLogin().then(() => {this.router.navigate(['/home']);
    // }).catch(error => console.error(error));
  }

  async onFacebookLogin() {
    await this.af.facebookLogin();
    // Get return URL and restore flag from query parameters
    const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    const restore = this.route.snapshot.queryParams['restore'] || null;

    // Redirect to return URL with restore flag if present
    if (restore) {
      this.router.navigate([returnUrl], { queryParams: { restore: true } });
    } else {
      this.router.navigateByUrl(returnUrl);
    }
    // this.af.facebookLogin().then(() => {
    //   this.router.navigate([this.returnUrl]) || '/';
    // }).catch(error => console.error(error));
  }

  switchMode() {
    this.isLoginMode = !this.isLoginMode;
  }

  putPhone() {
    // this.isByPhone = !this.isByPhone;
    
        this.errorPassword = "L'authentification par téléphone n'est pas encore disponible"
  }



}

