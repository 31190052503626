<main>


  <div id="wrap" class="oe_structure oe_empty" data-oe-model="ir.ui.view" data-oe-id="1309" data-oe-field="arch" data-oe-xpath="/t[1]/t[1]/div[1]">
    <section class="s_title parallax s_parallax_is_fixed pt24 pb24" data-vcss="001" data-snippet="s_title" data-scroll-background-ratio="1" data-name="Titre">
        <span class="s_parallax_bg oe_img_bg" style="background-color: rgba(31, 152, 31, 0.15); background-position: 50% 0;"></span>
        <div class="o_we_bg_filter bg-black-0"></div>
        <div class="container">
            <h1><font style="color: rgb(0, 0, 0);" *ngIf="isLoginMode">{{"Connexion" | translate}}</font></h1>
            <h1><font style="color: rgb(0, 0, 0);" *ngIf="!isLoginMode">{{"Inscription" | translate}}</font></h1>
        </div>
    </section>
    <section class="s_text_block o_colored_level" data-snippet="s_text_block" data-name="Texte">
            <div class="container">
                <div class="row" style="display: flex; align-items: center; justify-content: center;">
                    <div class="col-lg-3 o_colored_level">
                       
                            <form class="oe_login_form" [formGroup]="authForm" (ngSubmit)="onSubmit()">
                        
                                <!-- By Mail -->
                        
                                <div *ngIf="!isByPhone">
                                    <div class="field-login" *ngIf="!isLoginMode">
                                        <label for="fullname" class="form-label">{{"Nom Complet" | translate}}</label>
                                        <input type="text" placeholder="{{'Nom Complet' | translate}}" formControlName="fullname" id="fullname" class="form-control">
                                    </div>
                                    <div class="field-login">
                                        <label for="email" class="">{{"Email" | translate}}</label>
                                        <input type="text" placeholder="Email" formControlName="email" id="email" class="form-control ">
                                    </div>
                                    <div class="mb-3">
                                        <label for="password" class="form-label">{{"Mot de passe" | translate}}</label>
                                        <input type="password" placeholder="{{'Mot de passe' | translate}}" formControlName="password" id="password" minlength="6" maxlength="4096" class="form-control">
                                    </div>
                                    <div *ngIf="!credentialCorect"><h6 style="color: red;">{{"Email ou Mot de passe incorrect" | translate}}</h6></div>
                                    <div class="mb-3" *ngIf="!isLoginMode">
                                        <input type="password" placeholder="{{'Confirmez le mot de passe' | translate}}" formControlName="password2" id="password2" minlength="6" maxlength="4096" class="form-control">
                                        <!-- <div *ngIf="((authForm.get('password')?.value).length) < 6"><h5 style="color: red;">Le mot de passe doit être d'au-moins 6 caractères</h5></div> -->
                                    </div>
                                    <div class="clearfix oe_login_buttons text-centerd-grid" *ngIf="!isLoginMode">
                                        <button type="submit" class="btn btn-primary" style="width: 100%;">{{"S'inscrire" | translate}}</button>
                                    </div>
                                    <!-- <p>{{authForm.get("fullname")?.value}} {{authForm.get("email")?.value}} {{authForm.get("password")?.value}} {{authForm.get("password2")?.value}}</p> -->
                                    
                                    <div class="clearfix oe_login_buttons text-center d-grid" *ngIf="!isLoginMode">
                                        <label for="login">{{"Vous avez déjà un compte" | translate}}</label>
                                        <button (click)="switchMode()" class="btn btn-secondary" id="login" style="width: 100%; background-color:white;">{{"Se connecter" | translate}}</button>
                                    </div>
                                    <div class="clearfix oe_login_buttons text-center d-grid " *ngIf="isLoginMode">
                                        <button type="submit" class="btn btn-primary">{{"Se connecter" | translate}}</button>
                                        <div class="justify-content-between d-flex small nav-link" *ngIf="isLoginMode">
                                            <a routerLink="/reset-password">{{"Réinitialiser le mot de passe" | translate}}</a>
                                        </div>
                                        <label for="register" *ngIf="isLoginMode">{{"Vous n'avez pas de compte" | translate}}</label>
                                        <button (click)="switchMode()" class="btn btn-secondary" id="Register" style="width: 100%; background-color:white;" *ngIf="isLoginMode">{{"S'inscrire" | translate}}</button>
                                    </div>
                                    
                                </div>
                                
                                <!-- By Phone -->
                                
                                <div  data-name="Texte" *ngIf="isByPhone">
                                    <div class="col-12" style="display: flex; flex-direction: row;" *ngIf="!waitingCode">
                                        <div class="s_website_form_field mb-3 col-4 s_website_form_custom s_website_form_required">
                                          <label class="s_website_form_label" style="width: 200px" for="phonePrefix">
                                            <span class="s_website_form_label_content">{{"Pays" | translate}}</span>
                                          </label>
                                          <select id="phonePrefix" formControlName="phonePrefix" class="form-select s_website_form_input">
                                            <option *ngFor="let prefix of phonePrefixes" [value]="prefix.value">
                                              {{ prefix.label }}
                                            </option>
                                          </select>
                                        </div>
                                        <div class="mb-3 col-8" style="margin: 0px 0px 0px 5px;">
                                          <label class="s_website_form_label" style="width: 200px" for="phoneNumber">
                                            <span class="s_website_form_label_content">{{"Numéro de téléphone" | translate}}</span>
                                          </label>
                                          <input class="form-control s_website_form_input" type="tel" formControlName="phoneNumber" id="phoneNumber" maxlength="15">
                                        </div>
                                      </div>
                                      <div class="mb-3 field-login" *ngIf="waitingCode">
                                        <label for="code" class="form-label">{{"Code de confirmation" | translate}}</label>
                                        <input type="text" placeholder="{{'Code' | translate}}" formControlName="code" id="code" class="form-control">
                                      </div>
                                      <div class="clearfix oe_login_buttons text-center gap-1 d-grid mb-1 pt-3">
                                        <label for="codePhone" *ngIf="!waitingCode">{{"Vous allez recevoir un code par SMS à ce numéro pour vous connecter" | translate}}</label>
                                        <div id="recaptcha-container"></div>
                                        <button id="codePhone" class="btn btn-primary" *ngIf="!waitingCode" (click)="startPhoneNumberVerification()">{{"Recevoir le code" | translate}}</button>
                                        <!-- <button class="btn btn-primary" (click)="verifyPhoneNumber()">XConfirmer</button> -->
                                        <button id="codeFirebase" class="btn btn-primary" *ngIf="waitingCode" (click)="verifyPhoneNumber()">{{"Confirmer" | translate}}</button>
                                      </div>
                                      <div id="recaptcha-container"></div>
                                      <div><h6 style="color: red;">{{errorPassword}}</h6></div>
                                    <!-- <p>{{authForm.get("phonePrefix")?.value}} {{authForm.get("phoneNumber")?.value}} {{authForm.get("code")?.value}}</p> -->
                            </div>
                        
                            </form>
                            <div class="mobile-only" *ngIf="isMobile" >
                                <div>
                                    <div>
                                        <div class="s_hr" data-snippet="s_hr" data-name="Séparateur">
                                            <hr class="w-100 mx-auto" style="border-top-width: 1px; border-top-style: solid;">
                                        </div>
                                         <a><strong>{{"Ou continuez avec :" | translate}}</strong></a>
                                        <div class="s_social_media o_not_editable text-start" data-snippet="s_social_media" data-name="Réseaux sociaux">
                                            <a  target="_blank" aria-label="Autre réseau social" (click)="onGoogleLogin()">
                                                        <i class="fa fa-google o_editable_media text-black o_animate o_anim_bounce_in o_visible fa-stack fa-2x o_animated" style="animation-name: o_anim_bounce_in; animation-play-state: running; visibility: visible;"></i>
                                                    </a>
                                            <!-- <a *ngIf="!isByPhone" (click)="putPhone()" target="_blank" aria-label="Autre réseau social">
                                                        <i class="fa fa-phone o_editable_media text-black o_animate o_anim_bounce_in o_visible fa-stack fa-2x o_animated" style="animation-name: o_anim_bounce_in; animation-play-state: running; visibility: visible;"></i>
                                              </a> -->
                                            <!-- <a *ngIf="isByPhone" (click)="putPhone()" target="_blank" aria-label="Autre réseau social">
                                                        <i class="fa fa-envelope o_editable_media text-black o_animate o_anim_bounce_in o_visible fa-stack fa-2x o_animated" style="animation-name: o_anim_bounce_in; animation-play-state: running; visibility: visible;"></i>
                                              </a> -->
                                            <!-- <a target="_blank" aria-label="example" (click)="onFacebookLogin()">
                                                        <i class="fa fa-facebook-square o_editable_media text-black o_animate o_anim_bounce_in o_visible fa-stack fa-2x o_animated" style="animation-name: o_anim_bounce_in; animation-play-state: running; visibility: visible;"></i>
                                            </a> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                    
                    <div class="col-lg-2 d-lg-block d-none o_snippet_mobile_invisible">
                        <div>
                            <div>
                                <!-- <div class="s_hr" data-snippet="s_hr" data-name="Séparateur">
                                    <hr class="w-100 mx-auto" style="border-top-width: 1px; border-top-style: solid;">
                                </div> -->
                                <p class="o_default_snippet_text"><strong>{{"Ou continuez avec :" | translate}}</strong></p>
                                <div>
                                    <ul class="nav navbar-nav ">
                                        <li  role="presentation" (click)="onGoogleLogin()" class="login-icon">
                                            <a><i class="fa fa-google o_editable_media text-black o_animate o_anim_bounce_in o_visible fa-stack fa-2x o_animated" style="animation-name: o_anim_bounce_in; animation-play-state: running; visibility: visible;"></i></a>
                                            <a>Google</a>
                                        </li>
                                        
                                <div class="s_hr" data-snippet="s_hr" data-name="Séparateur">
                                    <hr class="w-100 mx-auto" style="border-top-width: 1px; border-top-style: solid;">
                                </div>
                                        <!-- <li (click)="putPhone()" *ngIf="!isByPhone" class="login-icon">
                                            <a><i class="fa fa-phone o_editable_media text-black o_animate o_anim_bounce_in o_visible fa-stack fa-2x o_animated" style="animation-name: o_anim_bounce_in; animation-play-state: running; visibility: visible;"></i></a>
                                            <a>Phone</a>
                                        </li> -->
                                        
                                <div class="s_hr" data-snippet="s_hr" data-name="Séparateur">
                                    <hr class="w-100 mx-auto" style="border-top-width: 1px; border-top-style: solid;">
                                </div>
                                        <li (click)="putPhone()" *ngIf="isByPhone" class="login-icon">
                                            <a><i class="fa fa-envelope o_editable_media text-black o_animate o_anim_bounce_in o_visible fa-stack fa-2x o_animated" style="animation-name: o_anim_bounce_in; animation-play-state: running; visibility: visible;"></i></a>
                                            <a>Mail</a>
                                        </li>
                                        <div class="s_hr" data-snippet="s_hr" data-name="Séparateur" *ngIf="isByPhone">
                                            <hr class="w-100 mx-auto" style="border-top-width: 1px; border-top-style: solid;">
                                        </div>
                                        <!-- <li (click)="onFacebookLogin()" class="login-icon">
                                            <a><i class="fa fa-facebook-square o_editable_media text-black o_animate o_anim_bounce_in o_visible fa-stack fa-2x o_animated" style="animation-name: o_anim_bounce_in; animation-play-state: running; visibility: visible;"></i></a>
                                            <a>Facebook</a>
                                        </li> -->
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>



















<!-- <section class="s_text_block pt10 o_colored_level pb0" data-name="Texte">
  
  <div  data-name="Texte" >

    <form class="oe_login_form" [formGroup]="authForm" (ngSubmit)="onSubmit()">


        <div *ngIf="!isByPhone">
            <div class="mb-3 field-login" *ngIf="!isLoginMode">
                <label for="fullname" class="form-label">Nom Complet</label>
                <input type="text" placeholder="Nom Complet" formControlName="fullname" id="fullname" class="form-control">
            </div>
            <div class="mb-3 field-login">
                <label for="email" class="form-label">Email</label>
                <input type="text" placeholder="Email" formControlName="email" id="email" class="form-control ">
            </div>
            <div class="mb-3">
                <label for="password" class="form-label">Mot de passe</label>
                <input type="password" placeholder="Mot de passe" formControlName="password" id="password" required="required" autocomplete="current-password" minlength="6" maxlength="4096" class="form-control ">
            </div>
            <div *ngIf="!credentialCorect"><h6 style="color: red;">Email ou Mot de passe incorrect</h6></div>
            <div class="mb-3" *ngIf="!isLoginMode">
                <input type="password" placeholder="Confirmez le mot de passe" formControlName="password2" id="password2" minlength="6" maxlength="4096" class="form-control">
            </div>
            <div class="clearfix oe_login_buttons text-center gap-1 d-grid mb-1 pt-3" *ngIf="!isLoginMode">
                <button type="submit" class="btn btn-primary">S'inscrire</button>
            </div>
            <div><h6 style="color: red;">{{this.errorPassword}}</h6></div>
            <div class="clearfix oe_login_buttons text-center gap-1 d-grid mb-1 pt-3" *ngIf="!isLoginMode">
                <label for="login">Vous avez déjà un compte</label>
                <button (click)="switchMode()" class="btn btn-secondary" id="login" style="width: 100%; background-color:white;">Se connecter</button>
            </div>
            <div class="clearfix oe_login_buttons text-center gap-1 d-grid mb-1 pt-3" *ngIf="isLoginMode">
                <button type="submit" class="btn btn-primary">Se connecter</button>
                <div class="justify-content-between mt-2 d-flex small nav-link" *ngIf="isLoginMode">
                    <a routerLink="/reset-password">Réinitialiser le mot de passe</a>
                </div>
                <label for="register" *ngIf="isLoginMode">Vous n'avez pas de compte</label>
                <button (click)="switchMode()" class="btn btn-secondary" id="Register" style="width: 100%; background-color:white;" *ngIf="isLoginMode">S'inscrire</button>
            </div>
            
        </div>
        
        <div  data-name="Texte" *ngIf="isByPhone">
            <div class="col-12 " style="display: flex; flex-direction:row;" *ngIf="!waitingCode">
                <div class="s_website_form_field mb-3 col-4 s_website_form_custom s_website_form_required">
                    <label class="s_website_form_label" style="width: 200px" for="owge5u1uo7zl">
                        <span class="s_website_form_label_content">Pays</span>
                    </label>
                    <select id="phonePrefix" formControlName="phonePrefix" class="form-select s_website_form_input">
                        <option *ngFor="let prefix of phonePrefixes" [value]="prefix.value" aria-sort="descending">
                            {{ prefix.label }}
                        </option>
                    </select>
                </div>
                <div class="mb-3 col-8" style="margin: 0px 0px 0px 5px;">
                    <label class="s_website_form_label" style="width: 200px" for="owge5u1uo7zl">
                        <span class="s_website_form_label_content">Numéro de téléphone</span>
                    </label>
                    <input class="form-control s_website_form_input" type="tel" name="Téléphone"
                    formControlName="phoneNumber" id="owge5u1uo7zl"maxlength="15" pattern="\d{1,15}">
                </div>
            </div>
            <div class="mb-3 field-login" *ngIf="waitingCode">
                <label for="name" class="form-label">Code de confirmation</label>
                <input type="text" placeholder="Code" formControlName="code" id="name" class="form-control">
            </div>
            <div class="clearfix oe_login_buttons text-center gap-1 d-grid mb-1 pt-3">
                <label for="codePhone" *ngIf="!waitingCode">Vous allez recevoir un code par SMS à ce numéro pour vous connecter</label>
                <button id="codePhone" class="btn btn-primary" *ngIf="!waitingCode" (click)="toggleWaiting()">Recevoir le code</button>
                <button id="codeFirebase" type="submit" class="btn btn-primary" *ngIf="waitingCode" >Confirmer</button>
            </div>
    </div>

    </form>
  </div>
</section>
<section data-snippet="s_text_block" data-name="Texte" class="o_colored_level" style="align-items: start; justify-content: start; width: 100%;">
  <div class="s_allow_columns o_container_small">
      <div class="row o_grid_mode" data-row-count="3">
          <div class="o_colored_level o_grid_item g-col-lg-8 g-height-4 col-lg-8" style="z-index: 1; grid-area: 1 / 3 / 5 / 11;">
              <div class="s_hr" data-snippet="s_hr" data-name="Séparateur">
                  <hr class="w-100 mx-auto" style="border-top-width: 1px; border-top-style: solid;">
              </div>
              <p class="o_default_snippet_text"><strong>Ou continuez avec :</strong></p>
              <div class="s_social_media o_not_editable text-start" data-snippet="s_social_media" data-name="Réseaux sociaux">
                  <a  target="_blank" aria-label="Autre réseau social" (click)="onGoogleLogin()">
                              <i class="fa fa-google o_editable_media text-black o_animate o_anim_bounce_in o_visible fa-stack fa-2x o_animated" style="animation-name: o_anim_bounce_in; animation-play-state: running; visibility: visible;"></i>
                          </a>
                  <a *ngIf="!isByPhone" (click)="putPhone()" target="_blank" aria-label="Autre réseau social">
                              <i class="fa fa-phone o_editable_media text-black o_animate o_anim_bounce_in o_visible fa-stack fa-2x o_animated" style="animation-name: o_anim_bounce_in; animation-play-state: running; visibility: visible;"></i>
                    </a>
                  <a *ngIf="isByPhone" (click)="putPhone()" target="_blank" aria-label="Autre réseau social">
                              <i class="fa fa-envelope o_editable_media text-black o_animate o_anim_bounce_in o_visible fa-stack fa-2x o_animated" style="animation-name: o_anim_bounce_in; animation-play-state: running; visibility: visible;"></i>
                    </a>
                  <a target="_blank" aria-label="example" (click)="onFacebookLogin()">
                              <i class="fa fa-facebook-square o_editable_media text-black o_animate o_anim_bounce_in o_visible fa-stack fa-2x o_animated" style="animation-name: o_anim_bounce_in; animation-play-state: running; visibility: visible;"></i>
                  </a>
              </div>
          </div>
      </div>
  </div>
</section> -->
</div>

  <div id="o_shared_blocks" class="oe_unremovable"></div>
          </main>