import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { environment } from '../environment';
import { Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

export interface Promotion {
  id?: string;
  title: string;
  description: string;
  startDate: Date | string;
  endDate: Date | string;
  type: string; // code (manual), auto (automatic), weight (weight-based), amount (amount-based)
  typeOfComputing: string; // percentage, fixed, gift
  amount: number;
  minAmount: number;
  minWeight: number;
  maxWeight: number;
  maxAmount: number;
  status: string; // active, inactive, expired
  code: string;
  countiesAllowed: string[];
  cumulable: boolean;
  limit: boolean;
  limitAmount: number;
  usageCount?: number;
  createdBy?: string;
  createdAt?: Date | string;
  updatedAt?: Date | string;
  isAutomatic?: boolean; // Flag to indicate if this promotion applies automatically
}

@Component({
  selector: 'app-promotion',
  templateUrl: './promotion.component.html',
  styleUrl: './promotion.component.css'
})
export class PromotionComponent implements OnInit {
  user!: null;
  promotionForm!: FormGroup;
  phonePrefixes = environment.phonePrefixes;
  europeanCountries = environment.euroPrefixes;
  currentMode: 'create' | 'edit' | 'details' = 'create';
  isLoading = false;
  errorMessage = '';
  successMessage = '';
  promotionId: string | null = null;
  promotion?: Promotion;
  
  promotionTypes = [
    { value: 'code', label: 'Code Promo (Manuel)' },
    // { value: 'general', label: 'Promotion générale' },
    // { value: 'weight', label: 'Réduction sur poids' },
    { value: 'amount', label: 'Réduction sur montant' },
    { value: 'other', label: 'Autre' },
  ];
  
  computingTypes = [
    { value: 'percentage', label: 'Pourcentage' },
    { value: 'fixed', label: 'Montant fixe' },
    { value: 'gift', label: 'Cadeau' }
  ];
  
  statusOptions = [
    { value: 'active', label: 'Actif' },
    { value: 'inactive', label: 'Inactif' },
    { value: 'expired', label: 'Expiré' }
  ];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private firestore: AngularFirestore,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.initForm();
    
    // Determine mode based on URL parameters
    this.route.queryParams.subscribe(params => {
      if (params['id']) {
        this.promotionId = params['id'];
        this.currentMode = params['edit'] ? 'edit' : 'details';
        this.loadPromotion(this.promotionId!);
      } else {
        this.currentMode = 'create';
      }
    });

    // Add conditional logic based on promotion type
    this.promotionForm.get('type')?.valueChanges.subscribe(type => {
      this.updateFormBasedOnType(type);
    });
    // set active of inactive based on startDate and endDate
    this.upadateFormBasedOnDate(this.promotionForm.get('startDate')?.value, this.promotionForm.get('endDate')?.value);

  }

  upadateFormBasedOnDate(startDate: any, endDate: any): void {
    const currentDate = new Date();
    const start = new Date(startDate);
    const end = new Date(endDate);

    if (start > currentDate && end > currentDate) {
      this.promotionForm.patchValue({ status: 'active' });
    } else if (start < currentDate && end > currentDate) {
      this.promotionForm.patchValue({ status: 'inactive' });
    } else if (end < currentDate) {
      this.promotionForm.patchValue({ status: 'expired' });
    }
  }

  initForm(): void {
    this.promotionForm = this.fb.group({
      title: ['', Validators.required],
      description: [''],
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      type: ['code'],
      gift_name: [''],
      typeOfComputing: ['percentage', Validators.required],
      hasAmountTranche: [false],
      amount: ['', [Validators.required, Validators.min(0)]],
      minAmount: [0, Validators.min(0)],
      maxAmount: [0, Validators.min(0)],
      hasWeightTranche: [false],
      minWeight: [0, Validators.min(0)],
      maxWeight: [0, Validators.min(0)],
      status: ['',],
      code: ['', [Validators.minLength(8), Validators.maxLength(8)]],
      countiesAllowed: this.buildCountriesArray(),
      cumulable: [false],
      limit: [false],
      usage: 0,
      limitAmount: [0, Validators.min(0)],
      isAutomatic: [false]
    });

    // Add conditional validation for limitAmount
    this.promotionForm.get('limit')?.valueChanges.subscribe(hasLimit => {
      const limitAmountControl = this.promotionForm.get('limitAmount');
      if (hasLimit) {
        limitAmountControl?.setValidators([Validators.required, Validators.min(1)]);
      } else {
        limitAmountControl?.setValidators(Validators.min(0));
      }
      limitAmountControl?.updateValueAndValidity();
    });
  }

  updateFormBasedOnType(type: string): void {
    const codeControl = this.promotionForm.get('code');
    const isAutomaticControl = this.promotionForm.get('isAutomatic');
    
    if (type === 'code') {
      codeControl?.setValidators([Validators.required, Validators.minLength(3), Validators.maxLength(15)]);
      isAutomaticControl?.setValue(false);
    } else {
      // For automatic promotions
      if (type === 'general' || type === 'weight' || type === 'amount') {
        isAutomaticControl?.setValue(true);
        codeControl?.setValidators([Validators.minLength(3), Validators.maxLength(15)]);
        
        // Generate automatic code if empty
        if (!codeControl?.value) {
          this.generateRandomCode();
        }
      }
    }
    
    codeControl?.updateValueAndValidity();
  }

  buildCountriesArray(): FormArray {
    const arr = this.fb.array([]);
    this.europeanCountries.forEach(() => {
      arr.push(new FormControl(false));
    });
    return arr;
  }

  getSelectedCountries(): string[] {
    const countryControls = this.promotionForm.get('countiesAllowed') as FormArray;
    let selectedCountries: string[] = [];
    
    countryControls.controls.forEach((control, index) => {
      if (control.value) {
        selectedCountries.push(this.europeanCountries[index].value);
      }
    });
    
    return selectedCountries;
  }

  loadPromotion(id: string): void {
    this.isLoading = true;
    this.firestore.collection('promotions').doc(id).get()
      .subscribe(
        doc => {
          if (doc.exists) {
            const data = doc.data();
            this.promotion = data && typeof data === 'object' ? { id: doc.id, ...data } as Promotion : undefined;
            if (this.currentMode !== 'create') {
              this.patchFormValues(this.promotion!);
            }
          } else {
            this.errorMessage = 'Promotion non trouvée';
            this.router.navigate(['/promotions']);
          }
          this.isLoading = false;
        },
        error => {
          this.errorMessage = `Erreur lors du chargement: ${error.message}`;
          this.isLoading = false;
        }
      );
  }

  patchFormValues(promotion: Promotion): void {
    // Convert dates to proper format for input fields
    const startDate = promotion.startDate instanceof Date 
      ? promotion.startDate.toISOString().split('T')[0] 
      : typeof promotion.startDate === 'string' ? promotion.startDate : '';
    
    const endDate = promotion.endDate instanceof Date 
      ? promotion.endDate.toISOString().split('T')[0] 
      : typeof promotion.endDate === 'string' ? promotion.endDate : '';

    // Update form with promotion data
    this.promotionForm.patchValue({
      title: promotion.title,
      description: promotion.description,
      startDate: startDate,
      endDate: endDate,
      type: promotion.type,
      typeOfComputing: promotion.typeOfComputing,
      amount: promotion.amount,
      minAmount: promotion.minAmount,
      minWeight: promotion.minWeight,
      maxWeight: promotion.maxWeight,
      maxAmount: promotion.maxAmount,
      status: promotion.status,
      code: promotion.code,
      cumulable: promotion.cumulable,
      limit: promotion.limit,
      limitAmount: promotion.limitAmount,
      isAutomatic: promotion.isAutomatic || false
    });

    // Handle countries selection
    if (promotion.countiesAllowed && promotion.countiesAllowed.length > 0) {
      const countryControls = this.promotionForm.get('countiesAllowed') as FormArray;
      countryControls.controls.forEach((control, index) => {
        control.setValue(
          promotion.countiesAllowed.includes(this.europeanCountries[index].value)
        );
      });
    }

    // Disable form for details view
    if (this.currentMode === 'details') {
      this.promotionForm.disable();
    }
  }

  switchToEditMode(): void {
    this.currentMode = 'edit';
    this.promotionForm.enable();
  }

  switchToDetailsMode(): void {
    this.currentMode = 'details';
    if (this.promotion) {
      this.patchFormValues(this.promotion);
    }
    this.promotionForm.disable();
  }

  async savePromotion(): Promise<void> {
    if (this.promotionForm.invalid) {
      this.markFormGroupTouched(this.promotionForm);
      this.errorMessage = 'Veuillez corriger les erreurs dans le formulaire.';
      return;
    }

    this.isLoading = true;
    const formData = this.promotionForm.value;
    const selectedCountries = this.getSelectedCountries();
    
    const promotionData: Partial<Promotion> = {
      ...formData,
      countiesAllowed: selectedCountries,
      updatedAt: new Date()
    };

    try {
      const user = await this.authService.getUser().pipe(take(1)).toPromise();
      
      if (!user) {
        throw new Error('Utilisateur non authentifié');
      }
      
      if (this.currentMode === 'create') {
        promotionData.createdBy = user.uid;
        promotionData.createdAt = new Date();
        promotionData.usageCount = 0;
        // SET STATUS BASED ON START AND END DATE
        const current_date = new Date();
        const startDate = new Date(formData.startDate);
        const endDate = new Date(formData.endDate);
        if (startDate <= current_date && endDate >= current_date) {
          promotionData.status = 'active';
        } else if (startDate > current_date && endDate < current_date) {
          promotionData.status = 'inactive';
        } else if (endDate < current_date) {
          promotionData.status = 'expired';
        }
        
        await this.firestore.collection('promotions').add(promotionData);
        this.successMessage = 'Promotion créée avec succès';
        this.router.navigate(['/promotions-list']);
      } else if (this.currentMode === 'edit' && this.promotionId) {
        // SET STATUS BASED ON START AND END DATE
        const current_date = new Date();
        const startDate = new Date(formData.startDate);
        const endDate = new Date(formData.endDate);
        if (startDate <= current_date && endDate >= current_date) {
          promotionData.status = 'active';
        } else if (startDate > current_date && endDate < current_date) {
          promotionData.status = 'inactive';
        } else if (endDate < current_date) {
          promotionData.status = 'expired';
        }
        await this.firestore.collection('promotions').doc(this.promotionId).update(promotionData);
        this.successMessage = 'Promotion mise à jour avec succès';
        
        // this.switchToDetailsMode();
        this.router.navigate(['/promotions-list']);
      }
    } catch (error: any) {
      this.errorMessage = `Erreur: ${error.message}`;
    } finally {
      this.isLoading = false;
    }
  }

  deletePromotion(): void {
    if (confirm('Êtes-vous sûr de vouloir supprimer cette promotion ?')) {
      this.isLoading = true;
      if (this.promotionId) {
        this.firestore.collection('promotions').doc(this.promotionId).delete()
          .then(() => {
            this.successMessage = 'Promotion supprimée avec succès';
            this.router.navigate(['/promotions']);
          })
          .catch(error => {
            this.errorMessage = `Erreur lors de la suppression: ${error.message}`;
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    }
  }

  generateRandomCode(): void {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let result = '';
    const length = 8;
    for (let i = 0; i < length; i++) {
      result += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    this.promotionForm.patchValue({ code: result });
  }

  cancel(): void {
    if (this.currentMode === 'edit' && this.promotionId) {
      this.switchToDetailsMode();
    } else {
      this.router.navigate(['/promotions-list']);
    }
  }

  // Helper method to mark all form controls as touched
  private markFormGroupTouched(formGroup: FormGroup): void {
    Object.values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      
      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      }
    });
  }
}
