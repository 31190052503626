import { Component, OnInit } from '@angular/core';
import { UserProfile } from '../models/user-profile';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from '../environment';

@Component({
  selector: 'app-create-employee',
  templateUrl: './create-employee.component.html',
  styleUrl: './create-employee.component.css'
})
export class CreateEmployeeComponent implements OnInit{
  userProfile?: UserProfile | null = null;
  credentialCorrect = true;
  employeeForm!: FormGroup;
  isLoading=false;
  cities = {'kinshasa': "Kinshasa", 'lubumbashi': "Lubumbashi", 'tubize': "Tubize"};
  abilities = {'delivery': "Livreur", 'stock': "Entrêpot", 'transit':"Transitaire", 'manager': "Manager", 'topManager': "Top Manager"};
  errorPassword = "";
  phonePrefixes = environment.phonePrefixes; // Assuming you have a list of phone prefixes in your environment file

  constructor(
    private router: Router,
    private af : AuthService,
    private fb: FormBuilder
  ){}
  ngOnInit(): void {
    this.employeeForm = this.fb.group({
      email: ['', [Validators.email]], // Wrap validators in an array
      password: ['', [Validators.minLength(6)]],
      password2: [''],
      phoneNumber: ['', Validators.required],
      phonePrefix: ["", Validators.required],
      // country:[""],
      // phoneNumber2: ['', [Validators.required, Validators.pattern(/^\+?\d{10,15}$/)]],
      fullname: [''],
      code: [''],
      ability:['', Validators.required],
      city:['']
    });
  }

  async onSubmit(){
    // const fullname = this.employeeForm.get("fullname")?.value;
    // const email = this.employeeForm.get("email")?.value;
    // const password = this.employeeForm.get("password")?.value;
    // const password2 = this.employeeForm.get("password2")?.value;
    // const ability = this.employeeForm.get("ability")?.value;
    // const city = this.employeeForm.get("city")?.value;
    const {fullname, email, password, password2, ability, city, phonePrefix, phoneNumber} = this.employeeForm.value;
    if (this.employeeForm.valid){
      if (password === password2){
        try {
          const { email, fullname, password, ability } = this.employeeForm.value;
    
          // Wait for the user creation to complete
          this.af.signUp4(email, password, fullname).then((user) => {
            if (user) {
              // If user exists, create the employee profile
              this.af.createEmployeeProfile({
                fullname,
                email,
                ability,
                isEmployee: true,
              }, user.uid);  // Pass the user UID here
            } else {
              console.error("User creation failed");
            }
          }).catch(error => {
            console.error("Error during employee creation: ", error);
          });
        }catch(error){}
      }else {
        console.log("Password didn't match");
        this.errorPassword = "Le mot de passe ne correspond pas"
      }
    }else {
      console.error("Form is not valid");
      this.errorPassword = "Veuillez remplir toutes les informations demandées";
    }
  }

}
