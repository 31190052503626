<main>
    <div id="wrap" class="oe_structure oe_empty" data-oe-model="ir.ui.view" data-oe-id="1309" data-oe-field="arch" data-oe-xpath="/t[1]/t[1]/div[1]">
        <section class="s_title parallax s_parallax_is_fixed pt24 pb24" data-vcss="001" data-snippet="s_title" data-scroll-background-ratio="1" data-name="Titre">
            <span class="s_parallax_bg oe_img_bg" style="background-color: rgba(31, 152, 31, 0.15); background-position: 50% 0;"></span>
            <div class="o_we_bg_filter bg-black-0"></div>
            <div class="container">
                <h1><font style="color: rgb(0, 0, 0);">{{"Description du colis" | translate}}</font></h1>
            </div>
        </section>
        <section class="s_text_block" data-snippet="s_text_block" data-name="Texte">
            <div class="s_allow_columns container">
                <div class="row">
                    <div class="col-lg-8 mt-4 mt-lg-0 o_colored_level">
                        <!-- old Contact form -->
                        <section class="s_website_form o_colored_level" data-vcss="001" data-snippet="s_website_form" data-name="Formulaire" id="Formulaire" data-anchor="true">
                            <div class="o_container_small">
                                <form  data-mark="*" data-pre-fill="true" [formGroup]="parcelForm" (ngSubmit)="onSubmit()"
                                class="o_mark_optional">
                                <section class="s_text_image o_colored_level" data-snippet="s_text_image" data-name="Texte - Image">
                                    <div class="container">
                                        <div class="row align-items-center">
                                            <div class="o_colored_level col-lg-6">
                                                <font>{{"Pour garantir l'envoi de votre paquet, assurez-vous que la somme des trois côtés de votre paquet ne dépasse pas 150cm" | translate}}<br>A+B+C &lt;= 150cm</font>
                                        </div>
                                            <div class="o_colored_level col-lg-4">
                                                <img src="assets/carton BEFRET (1).png" alt=""
                                                class="img mx-auto rounded o_we_custom_image o_we_image_cropped"
                                                data-mimetype="image/webp"
                                                data-mimetype-before-conversion="image/png" loading="lazy"
                                                style="width: 70%; height: 70%;" />
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                    <div class="s_website_form_rows row s_col_no_bgcolor">
                                            <div data-name="Field" class="s_website_form_field mb-3 col-12 s_website_form_custom" data-type="many2one">
                                                <label class="s_website_form_label" style="width: 200px" for="type">
                                                    <span class="s_website_form_label_content">{{"Type de colis" | translate}} *</span>
                                                </label>
                                                <select class="form-select s_website_form_input" id="type" (change)="calculateCost()" formControlName="type">
                                                    <option *ngFor="let type of parcel_type | keyvalue" [value]="type.value">{{type.value}}</option>
                                                </select>
                                            </div>

                                            
                                            <div data-name="Field" class="s_website_form_field mb-3 col-12 s_website_form_custom ">
                                                <label class="s_website_form_label" style="width: 200px" for="city">
                                                    <span class="s_website_form_label_content">{{"Ville de destination" | translate}} *</span>
                                                </label>
                                                <select class="form-select s_website_form_input o_anim_pulse" id="city" (change)="calculateCost()" formControlName="city">
                                                    <option *ngFor="let city of cities | keyvalue" [value]="city.key">{{city.value}}</option>
                                                </select>
                                            </div>
                                            <div data-name="Field" class="s_website_form_field mb-3 col-12 s_website_form_custom s_website_form_required" data-type="selection">
                                                <label class="s_website_form_label" style="width: 200px" for="pickupMethod">
                                                    <span class="s_website_form_label_content">{{"Mode de livraison" | translate}} *</span>
                                                </label>
                                                <div class="row s_col_no_resize s_col_no_bgcolor s_website_form_multiple" data-name="Mode de livraison" data-display="horizontal">
                                                    <div class="radio col-12 col-lg-4 col-md-6">
                                                        <div class="form-check">
                                                            <input type="radio" class="s_website_form_input form-check-input" id="pickupMethod" formControlName="pickupMethod"
                                                            value="warehouse" (change)="calculateCost()">
                                                            <label class="form-check-label s_website_form_check_label" for="warehouse">{{"Point de rencontre (Gratuit)" | translate}}</label>
                                                        </div>
                                                    </div>
                                                    <div class="radio col-12 col-lg-4 col-md-6">
                                                        <div class="form-check">
                                                            <input type="radio" class="s_website_form_input form-check-input" id="pickupMethod" formControlName="pickupMethod"
                                                            value="home_delivery" (change)="calculateCost()">
                                                            <label class="form-check-label s_website_form_check_label" for="home_delivery">{{"Livraison à domicile €6" | translate}}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- <div data-name="Field" class="s_website_form_field mb-3 col-12 s_website_form_custom s_website_form_required" data-type="char">
                                                <label class="s_website_form_label" style="width: 200px" for="description">
                                                    <span class="s_website_form_label_content">Nommez votre colis *</span>
                                                </label>
                                                <input class="form-control s_website_form_input" type="text"
                                                placeholder="Ex : Cadeaux de noêl pour maman" id="description" formControlName="description">
                                            </div> -->
                                            
                                            <div *ngIf="((parcelForm.get('type')?.value) === 'Paquet')" data-name="Field" class="s_website_form_field mb-3 col-12 s_website_form_custom s_website_form_required" data-type="char">
                                                <label class="s_website_form_label" style="width: 200px" for="weight">
                                                    <span class="s_website_form_label_content">{{"Poids du colis (KG)" | translate}}*</span>
                                                </label>
                                                <input class="form-control s_website_form_input" type="number"
                                                id="weight" data-fill-with="weight" formControlName="weight" min="1.0" (change)="calculateCost()" >
                                                <div *ngIf="parcelForm.get('weight')?.value > 32"><h6 style="color: primary;">{{"Si le poids de votre paquet dépasse 32Kg, il sera livré 3 jours plutard" | translate}}</h6></div>
                                                <div><h5 style="color: primary;">{{"Assurez-vous d'indiquer le poids exact de votre paquet" | translate}}</h5></div>
                                            </div>
                                            <div data-name="Field" class="col-12" *ngIf="((parcelForm.get('type')?.value) === 'Paquet')">
                                                <label class="s_website_form_label" style="width: 200px" for="items">
                                                    <span class="s_website_form_label_content"><strong>{{"Composition du colis" | translate}}</strong></span>
                                                </label>
                                                <ng-container formArrayName="items" style="display: flex; align-items: center; justify-content: center;">
                                                    <div *ngFor="let item of items.controls; let i=index" [formGroupName]="i">
                                                        <div class="col-12" style="display: flex;">
                                                            <div class="col-7">
                                                                <input class="form-control s_website_form_input" type="text" 
                                                                placeholder="{{'Description' | translate}}" id="itemDescription" data-fill-with="name" formControlName="itemDescription">
                                                            </div>
                                                            <div class="col-2" style="margin: 0px 2px 0px 5px;">
                                                                <input class="form-control s_website_form_input" type="number" min="1.0" 
                                                            placeholder="{{'Valeur(€)' | translate}}" id="itemValue" data-fill-with="name" formControlName="itemValue">
                                                            </div>
                                                            <div class="col-2">
                                                                <input class="form-control s_website_form_input" type="number" style="margin: 0px 2px 0px 5px;"
                                                            placeholder="{{'Nbre' | translate}}" id="numberOfItems" min="1.0" formControlName="numberOfItems">
                                                            </div>
                                                            <span style="align-items: center; align-self: flex-end; justify-content: center;
                                                            display: flex; color: rgba(255, 0, 0, 0.673);
                                                            border-radius: 30px; width: 40px; height: 40px;" (click)="removeItem(i)">
                                                                <mat-icon>delete_forever_outline</mat-icon>
                                                            </span>
                                                        </div>
                                                    </div>

                                                </ng-container>

                                                <div  (click)="addItem()" style="display: flex; flex-direction: row; align-items: center; width: 80px; height: 60px;
                                                    color: rgba(0, 128, 0, 0.596); border-color: black; border-radius: 10px; cursor: pointer;" class="login-icon">
                                                    <span><mat-icon>add_circle_outline</mat-icon></span>{{'Ajouter' | translate}}

                                                </div>

                                            </div>

                                            <div data-name="Field" class="s_website_form_field mb-3 col-12 s_website_form_custom s_website_form_required" 
                                            data-type="text" *ngIf="(parcelForm.get('pickupMethod')?.value === 'home_delivery')">
                                                <label class="s_website_form_label" style="width: 200px" for="address">
                                                    <span class="s_website_form_label_content">{{'Adresse de livraison' | translate}} *</span>
                                                </label>
                                                <textarea class="form-control s_website_form_input" formControlName="address" name="Adresse de livraison" 
                                                placeholder="" id="address" rows="3"></textarea>
                                            </div>
                                            
                                            <div class="s_hr pb16 pt16" data-name="Séparateur">
                                                <hr class="w-100 mx-auto" style="border-top-width: 1px; border-top-style: solid; border-color: var(--600);">
                                            </div>
                                            <div data-name="Field"
                                            class="s_website_form_field mb-3 col-12 s_website_form_custom s_website_form_required"
                                            data-type="char">
                                                <label class="s_website_form_label" style="width: 200px" for="receiver_name">
                                                    <span class="s_website_form_label_content">{{'Coordonnées du destinataire' | translate}} *</span>
                                                </label>
                                                <div class="position-relative">
                                                    <input class="form-control s_website_form_input" type="text"
                                                           placeholder="{{'Nom complet du destinataire' | translate}}" id="receiver_name" formControlName="receiver_name">
                                                    <!-- Autocomplete dropdown -->
                                                    <div *ngIf="showReceiverAutocomplete && filteredReceivers.length > 0" class="autocomplete-dropdown">
                                                        <ul class="list-group">
                                                            <li *ngFor="let receiver of filteredReceivers" 
                                                                class="list-group-item list-group-item-action"
                                                                (click)="selectReceiver(receiver)">
                                                                {{ receiver.fullname }} ({{ receiver.phoneNumber }})
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                            <div style="display: flex; flex-direction:row;">
                                                <div class="s_website_form_field mb-3 col-4 s_website_form_custom s_website_form_required">
                                                    <!-- <label class="s_website_form_label" style="width: 200px" for="phonePrefix1">
                                                        <span class="s_website_form_label_content">Pays *</span>
                                                    </label> -->
                                                    <select id="phonePrefix1" formControlName="phonePrefix1" class="form-select s_website_form_input">
                                                        <option *ngFor="let prefix of phonePrefixes" [value]="prefix.value">
                                                            {{ prefix.label }}
                                                        </option>
                                                    </select>
                                                </div>
                                                <div class="mb-3 col-8" style="margin: 0px 0px 0px 5px;">
                                                    <input class="form-control s_website_form_input" type="tel" placeholder="{{'Téléphone du destinataire' | translate}}"
                                                    formControlName="receiver_phone" id="receiver_phone" maxlength="15">
                                                </div>
                                            </div>
                                            <div class="s_hr pb16 pt16" data-name="Séparateur">
                                                <hr class="w-100 mx-auto" style="border-top-width: 1px; border-top-style: solid; border-color: var(--600);">
                                            </div>
                                            <div data-name="Field"
                                            class="s_website_form_field mb-3 col-12 s_website_form_custom s_website_form_required"
                                            data-type="char">
                                                <label class="s_website_form_label" style="width: 200px" for="sender_name">
                                                    <span class="s_website_form_label_content">{{'Vos Coordonnées' | translate}} *</span>
                                                </label>
                                                <input class="form-control s_website_form_input" type="text" 
                                                placeholder="{{'Votre nom complet' | translate}}" id="sender_name" formControlName="sender_name">
                                            </div>
                                            <div style="display: flex; flex-direction:row;">
                                                <div class="s_website_form_field mb-3 col-4 s_website_form_custom s_website_form_required">
                                                    <select *ngIf="userProfile?.country" id="phonePrefix2" formControlName="phonePrefix2" class="form-select s_website_form_input"
                                                    [ngModel]="userProfile?.country">
                                                        <option *ngFor="let prefix of phonePrefixes"  [value]="prefix.label">
                                                            {{ prefix.label }} 
                                                        </option>
                                                    </select>
                                                    <select *ngIf="userProfile?.country == null" id="phonePrefix2" formControlName="phonePrefix2" class="form-select s_website_form_input" >
                                                        <option *ngFor="let prefix of phonePrefixes" [value]="prefix.label">
                                                            {{prefix.label}}
                                                        </option>
                                                    </select>
                                                </div>
                                                <div class="mb-3 col-8" style="margin: 0px 0px 0px 5px;">
                                                    <input class="form-control s_website_form_input" type="tel" placeholder="{{'Votre téléphone' | translate}}"
                                                    formControlName="sender_phone" id="sender_phone" maxlength="15">
                                                </div>
                                            </div>

                                            
                                            
                                            <div style="display: flex; flex-direction:row;">
                                                    <input type="checkbox" id="condition" formControlName="condition" class="form-check-input" style="margin-right: 5px;">
                                                <!-- </div> -->
                                                <div class="col-lg-12">
                                                    <!-- <div class="col-8"> -->
                                                        <label for="condition">{{"J'affirme avoir lu et approuvé toutes les" | translate}} <span class="base-fs text-black">
                                                            <a class="link" href="/conditions" data-bs-original-title="" title="">{{"conditions générales d'utilisations" | translate}}</a>
                                                            </span>{{" de BeFret." | translate}}</label>
                                                    <!-- </div> -->
                                                </div>
                                            </div>
                                            <div><h6 style="color: red;">{{this.errorMessage}}</h6></div>

                                            <div class="mb-0 py-2 col-12 s_website_form_submit pb96" data-name="Submit Button">
                                                <div class="s_website_form_label col-6">
                                                    <button class="btn btn-secondary" style="margin: 0px 10px 0px 0px;" [routerLink]="['/home']">{{"Annuler" | translate}}</button>
                                                    <button type="submit" class="btn btn-primary" [disabled]="!(parcelForm.get('condition')?.value) || isLoading">
                                                        <span *ngIf="isLoading" class="spinner-container">
                                                            <mat-spinner [diameter]="15" strokeWidth="3" color="accent"></mat-spinner>
                                                        </span>
                                                        <span *ngIf="!isLoading">{{"Suivant" | translate}}</span>
                                                        <span *ngIf="isLoading">{{"Chargement..." | translate}}</span>
                                                        <!-- <span></span> -->
                                                        
                                                    </button>
                                                    <!-- <button></button> -->
                                                </div>
                                                <span id="s_website_form_result"></span>

                                            </div>
                                        <!-- -->

                                    </div>
                                </form>
                            </div>
                        </section>
                    </div>

                    <div class="col-lg-4 mt-4 mt-lg-0 o_colored_level d-lg-block d-none o_snippet_mobile_invisible" data-invisible="1" style="position: fixed; right: 0; overflow: hidden;">
                        <!-- <div class="col-lg-4 mt-4 mt-lg-0 o_colored_level d-lg-block d-none o_snippet_mobile_invisible" data-invisible="1"></div> -->
                        <ul class="list-unstyled mb-0 ps-2">
                            <li><h1>{{"Résumé" | translate}}</h1></li>
                            <div class="s_hr pb10 p16" data-name="Séparateur">
                                <hr class="w-100 mx-auto" style="border-top-width: 1px; border-top-style: solid; border-color: var(--600);">
                            </div>
                            <li>{{"Type de colis : " | translate}}{{parcelForm.get("type")?.value}}</li>
                            <li>{{"Destinataire : " | translate}}{{parcelForm.get("full_name")?.value}}</li>
                            <li>{{"Elements du colis : " | translate}}{{items.length}}</li>
                            <li><font style="font-size: medium;"><strong>{{"Poids total : " | translate}}{{totalWeight}}KG</strong> </font></li>
                            <li><font style="font-size: small;"><strong><del>{{"Coût : € " | translate}}{{normal_cost | number:'1.2-2'}}</del></strong> </font></li>
                            <li><font style="font-size: medium;"><strong>{{"Coût : € " | translate}}{{cost | number:'1.2-2'}}</strong> </font></li>
                            <!-- <li><font style="font-size: medium;"><strong>Elements : {{}}</strong> </font></li> -->
                            <!-- <li><i class="fa fa-map-marker fa-fw me-2"></i>
                                <span class="o_force_ltr">35,&nbsp;Rue Ilya&nbsp;PRIGOGINE,&nbsp;1480&nbsp;Saintes (Tubize)</span>
                            </li>
                            <li><i></i><span class="o_force_ltr">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Belgique</span></li>
                            <li><i class="fa fa-phone fa-fw me-2"></i><span class="o_force_ltr">+32 465 26 58 48</span></li>
                            <li><i class="fa fa-1x fa-fw fa-envelope me-2"></i>info&#64;befret.be</li> -->
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        <section class="s_title parallax s_parallax_is_fixed bg-black-25 pt24 pb24 mobile-fixed-bottom mobile-only"
        data-vcss="001" data-snippet="s_title" data-scroll-background-ratio="1" data-name="Titre" *ngIf="isMobile">
            <span class="s_parallax_bg oe_img_bg" style="background-color: rgba(31, 152, 31, 0.407); background-position: 50% 0;"></span>
            <div class="o_we_bg_filter bg-black-25"></div>
            <div class="container" style="display: flex; flex-direction: column;">
                <h2><font style="color: white;">{{"Commande :" | translate}}</font></h2>
                <ul class="list-unstyled mb-0 ps-2">
                    <li><font style="color: white;">{{"Type de colis : " | translate}}{{parcelForm.get("type")?.value}}</font></li>
                    <li><font style="font-size: medium; color: white;"><strong>{{"Poids total : " | translate}}{{totalWeight}}KG</strong> </font></li>
                    <li><font style="font-size:small; color: white;"><strong><del>{{"Coût : € " | translate}}{{normal_cost | number:'1.2-2'}}</del></strong> </font></li>
                    <li><font style="font-size: medium; color: white;"><strong>Promo {{"Coût : € " | translate}}{{cost | number:'1.2-2'}}</strong> </font></li>
                </ul>
            </div>
        </section>

    </div>

    <div id="o_shared_blocks" class="oe_unremovable" data-oe-id="1361" data-oe-xpath="/data/xpath/div" data-oe-model="ir.ui.view" data-oe-field="arch"></div>
</main>
