import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Firestore, doc, setDoc, getDoc } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import firebase from 'firebase/compat/app';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { UserProfile } from './models/user-profile';
import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
// import { EmployeeProfile } from './staff/employee';
import { deleteDoc } from 'firebase/firestore';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user: firebase.User | null = null;

  constructor(private afAuth: AngularFireAuth, private firestore: Firestore, private router: Router, private fStore:AngularFirestore) {
    this.afAuth.authState.subscribe(user => {
      this.user = user;
      if (user) {
        this.checkAndCreateUserProfile(user);
      }
    });
  }

  isLoggedIn(): boolean {
    return !!this.user;
  }
  createEmployeeProfile(employeeData: Partial<UserProfile>, uid: string) {
    const userRef = doc(this.firestore, `users/${uid}`);
    return setDoc(userRef, {
      ...employeeData,
      isEmployee: true,  // Set as employee
    }, { merge: true });  // Use merge to avoid overwriting existing fields
  }
  // Check if the user profile exists, if not, create a new profile
  private async checkAndCreateUserProfile(user: firebase.User) {
    const userProfile = await this.getUserProfile(user.uid);
    let phoneNumber: string | undefined;
    let country: string | undefined;
    const savedFormData = localStorage.getItem('parcelFormData');
    if (savedFormData) {
      const parsedData = JSON.parse(savedFormData);
      phoneNumber = parsedData.sender_phone;
      country = parsedData.phonePrefix2;
    }
    if (!userProfile) {
      const newUserProfile: UserProfile = {
        uid: user.uid,
        email: user.email || '',
        fullname: user.displayName || '',
      };
      
      // Add phone and country if provided
      if (phoneNumber) newUserProfile.phoneNumber = phoneNumber;
      if (country) newUserProfile.country = country;
      
      await this.saveUserProfile(newUserProfile);
    }
  }

  // Save user profile to Firestore
  public async saveUserProfile(userProfile: UserProfile) {
    const userRef = doc(this.firestore, `users/${userProfile.uid}`);
    await setDoc(userRef, userProfile);
  }

  // Get user profile from Firestore
  getUserProfile(uid: string): Promise<UserProfile | null> {
    const userDocRef = doc(this.firestore, `users/${uid}`);
    return getDoc(userDocRef).then(snapshot => {
      if (snapshot.exists()) {
        return snapshot.data() as UserProfile;
      } else {
        return null;
      }
    });
  }

  getUserProfileObservable(): Observable<UserProfile | null> {
    return this.afAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          const userDocRef = doc(this.firestore, `users/${user.uid}`);
          return getDoc(userDocRef).then(snapshot => {
            if (snapshot.exists()) {
              return snapshot.data() as UserProfile;
            } else {
              return null;
            }
          });
        } else {
          return of(null);
        }
      })
    );
  }
  async signUp4(email: string, password: string, fullname: string): Promise<firebase.User | null> {
    try {
      const userCredential = await this.afAuth.createUserWithEmailAndPassword(email, password);
      const user = userCredential.user;
      if (user) {
        const newUserProfile: UserProfile = {
          uid: user.uid,
          email,
          fullname
        };
        await this.saveUserProfile(newUserProfile);
        return user;  // Return the user object
      }
    } catch (error) {
      console.error("Error during sign up: ", error);
      throw error;
    }
    return null;  // Return null if no user was created
  }
  // Extended Email/Password Sign-Up
  async signUp(email: string, password: string, fullname: string, phoneNumber?: string, country?: string) {
    try {
      const userCredential = await this.afAuth.createUserWithEmailAndPassword(email, password);
      const user = userCredential.user;
      if (user) {
        const newUserProfile: UserProfile = {
          uid: user.uid,
          email,
          fullname
        };
        
        if (phoneNumber) newUserProfile.phoneNumber = phoneNumber;
        if (country) newUserProfile.country = country;
        
        await this.saveUserProfile(newUserProfile);
      }
    } catch (error) {
      console.error("Error during sign-up:", error);
      throw error;
    }
  }

  // Email/Password Login
  login(email: string, password: string) {
    return this.afAuth.signInWithEmailAndPassword(email, password);
  }

  // Phone Number Login
  async loginWithPhoneNumber(phoneNumber: string, appVerifier: firebase.auth.RecaptchaVerifier) {
    try {
      return await this.afAuth.signInWithPhoneNumber(phoneNumber, appVerifier);
    } catch (error) {
      console.error("Error on phone login service:", error);
      throw error;
    }
  }

  // Verify Phone Number
  verifyPhoneNumber(code: string, verificationId: string) {
    const credential = firebase.auth.PhoneAuthProvider.credential(verificationId, code);
    return this.afAuth.signInWithCredential(credential);
  }

  // Google Login
  googleLogin(phoneNumber?: string, country?: string) {
    const provider = new firebase.auth.GoogleAuthProvider();
    return this.oAuthLogin(provider);
  }

  // Facebook Login
  facebookLogin(phoneNumber?: string, country?: string) {
    const provider = new firebase.auth.FacebookAuthProvider();
    return this.oAuthLogin(provider);
  }

  private async oAuthLogin(provider: firebase.auth.AuthProvider) {
    try {
      const credential = await this.afAuth.signInWithPopup(provider);
      if (credential.user) {
        // Pass phone and country to checkAndCreateUserProfile
        await this.checkAndCreateUserProfile(credential.user);
      }
    } catch (error) {
      console.error("OAuth Login Error:", error);
      throw error;
    }
  }

  // Logout
  logout() {
    return this.afAuth.signOut().then(() => {
      this.router.navigate(['/auth']);
    });
  }

  // Get Auth State
  getUser() {
    return this.afAuth.authState;
  }
  getEmployees(): Observable<any[]> {
    return this.fStore.collection('users', ref => ref.where('isEmployee', '==', true)).valueChanges();
  }
  
  deleteEmployee(uid: string) {
    return this.fStore.collection('users').doc(uid).delete();
  }
  
}
