import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AuthService } from '../auth.service';
import { Route, Router } from '@angular/router';


@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrl: './history.component.css'
})
export class HistoryComponent implements OnInit{
  notDisplyadeparcels: any[] = []; // Array to store the parcels
  parcels: any[] = []; // Array to store the parcels

  userId?: string; // Store the user's ID

  constructor(
    private firestore: AngularFirestore, 
    private authService: AuthService,
    private router: Router
  ){

  }
  ngOnInit(): void {
    this.authService.getUser().subscribe(user => {
      if (user) {
        this.userId = user.uid;
        this.fetchUserParcels();
        console.log("User: ", this.userId);
      }
    });
  }

  
  fetchUserParcels() {
    // Fetch parcels where the userId matches the current user's ID
    this.firestore.collection('parcel', ref => ref.where('uid', '==', this.userId))
      .valueChanges()
      .subscribe((data: any[]) => {
        this.notDisplyadeparcels = data;
        console.log("All Parcels:", this.notDisplyadeparcels);
        
        // Clear the parcels array
        this.parcels = [];
        
        // Use a for loop to filter parcels with valid trackingIDs
        for (let i = 0; i < this.notDisplyadeparcels.length; i++) {
          const parcel = this.notDisplyadeparcels[i];
          if (parcel.trackingID && 
              typeof parcel.trackingID === 'string' && 
              parcel.trackingID.trim() !== '') {
            this.parcels.push(parcel);
          }
        }
        
        console.log("Parcels with valid tracking IDs:", this.parcels);
      });
  }
  viewDetails(trackingID: string): void {
    this.router.navigate(['/details'], { queryParams: { trackingID } });
  }

}
