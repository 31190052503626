import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AuthService } from '../auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { user } from '@angular/fire/auth';

@Component({
  selector: 'app-list-promot',
  templateUrl: './list-promot.component.html',
  styleUrl: './list-promot.component.css'
})
export class ListPromotComponent implements OnInit{
viewPromotion(promotionId: any) {
  this.router.navigate(['/promotion'], { queryParams: { id: promotionId } });
}
editPromotion(promotionId: any) {
  this.router.navigate(['/promotion'], { queryParams: { id: promotionId, edit: true } });
}
  isLoading = false;
  errorMessage = '';
  successMessage = '';

  pausePromotion(promotionId: string) {
    if (!promotionId) {
      this.errorMessage = 'ID de promotion invalide';
      return;
    }

    this.isLoading = true;
    this.authService.getUser().subscribe(
      user => {
        if (user) {
          this.firestore.collection('promotions').doc(promotionId).update({
            status: 'inactive',
            updatedAt: new Date()
          })
          .then(() => {
            this.successMessage = 'La promotion a été mise en pause';
          })
          .catch(error => {
            this.errorMessage = `Erreur lors de la mise en pause: ${error.message}`;
          })
          .finally(() => {
            this.isLoading = false;
          });
        } else {
          this.errorMessage = 'Vous devez être connecté pour effectuer cette action';
          this.isLoading = false;
        }
      },
      error => {
        this.errorMessage = `Erreur d'authentification: ${error.message}`;
        this.isLoading = false;
      }
    );
  }

  deletePromotion(promotionId: any) {
    console.log('Attempting to delete promotion with ID:', promotionId);
    
    if (!promotionId) {
      this.errorMessage = 'ID de promotion invalide';
      console.error('Invalid promotion ID:', promotionId);
      return;
    }
    
    if (confirm('Êtes-vous sûr de vouloir supprimer cette promotion ?')) {
      this.isLoading = true;
      
      this.authService.getUser().subscribe(
        user => {
          if (user) {
            this.firestore.collection('promotions').doc(promotionId).delete()
              .then(() => {
                this.successMessage = 'Promotion supprimée avec succès';
                console.log('Promotion deleted successfully');
                // No need to navigate since we're already on the list page
                // this.router.navigate(['/promotions']);
              })
              .catch(error => {
                this.errorMessage = `Erreur lors de la suppression: ${error.message}`;
                console.error('Error deleting promotion:', error);
              })
              .finally(() => {
                this.isLoading = false;
              });
          } else {
            this.errorMessage = 'Vous devez être connecté pour effectuer cette action';
            this.isLoading = false;
          }
        },
        error => {
          this.errorMessage = `Erreur d'authentification: ${error.message}`;
          this.isLoading = false;
          console.error('Authentication error:', error);
        }
      );
    }
  }

  promotions: any[] = [];
  userId?: string;

  constructor(
    private firestore: AngularFirestore,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
  ){}

  ngOnInit(): void {
    this.authService.getUser().subscribe(user => {
      if (user) {
        this.userId = user.uid;
        this.fetchPromotions();
      }
    });
  }
  fetchPromotions() {
    this.isLoading = true;
    this.firestore.collection('promotions').snapshotChanges().subscribe(
      actions => {
        this.promotions = actions.map(a => {
          const data = a.payload.doc.data() as any;
          const id = a.payload.doc.id;
          return { id, ...data };  // This combines the id with the document data
        });
        console.log('Promotions with IDs:', this.promotions);
        this.isLoading = false;
      },
      error => {
        this.errorMessage = `Erreur lors du chargement: ${error.message}`;
        console.error('Error loading promotions:', error);
        this.isLoading = false;
      }
    );
  }
}


