import { Component, OnInit} from '@angular/core';
import { AuthService } from '../auth.service';
import { UserProfile } from '../models/user-profile';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { TranslationService } from '../translate.service';
@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrl: './top-bar.component.css'
})
export class TopBarComponent implements OnInit {
  userProfile?: UserProfile | null = null;
  xUser = this.authService.getUser();
  currentLanguage: string;
  mails : any;
  mailCount! : number;
  constructor(public authService: AuthService,
    private firestore: AngularFirestore,
    private translationService: TranslationService
  ) {
    this.currentLanguage = localStorage.getItem('appLanguage') || 'fr';
   }
  getMailCount() {
    this.firestore.collection('messages', ref => ref.where('status', '==', 'unread'))
      .valueChanges().subscribe((data: any[]) => {
        this.mails = data;
        this.mailCount = this.mails.length;
        console.log('Mails:', this.mails); // Log the retrieved data
        console.log('Mail count:', this.mailCount); // Log the mail count
      });
  }
  ngOnInit() {
    this.authService.getUser().subscribe(user => {
      if (user) {
        this.authService.getUserProfile(user.uid).then(profile => {
          this.userProfile = profile;
          this.getMailCount();
        });
      }
    });
    
  }

  onLogout() {
    this.authService.logout();
  }
  onMobileMenu() {
    console.log("Menu was clicked")
  }
  setLanguage(language: string) {
    this.translationService.changeLanguage(language);
    this.currentLanguage = language;
    window.location.reload();
  }
  
}


