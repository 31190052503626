import { Component, ChangeDetectorRef, OnInit} from '@angular/core';
import { Observable } from 'rxjs';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AuthService } from '../auth.service';
// import { ChartOptions, ChartType, ChartDataset } from 'chart.js';
// import { Label } from 'ng2-charts';
import { ChartOptions, ChartType, ChartDataset } from 'chart.js';
import { Router } from '@angular/router';
import { UserProfile } from '../models/user-profile';
@Component({
  selector: 'app-parcel-description',
  templateUrl: './parcel-description.component.html',
  styleUrl: './parcel-description.component.css'
})
export class ParcelDescriptionComponent implements OnInit{
  Math = Math;
  userProfile?: UserProfile | null = null;
  scaleMarkers: number[] = [];
mailList(mail: any) {
  this.router.navigate(['parcel-list'], {queryParams: {mail}});
}
  viewDetails(trackingID: any) {
    this.router.navigate(['/status'], { queryParams: { trackingID } });
  }
  countryList(country: any) {
    this.router.navigate(['parcel-list'], {queryParams: {country}});
  }
  deliveredList(delivered: any) {
    this.router.navigate(['parcel-list'], {queryParams: {delivered}});
  }
  packageList(paquet: any){
    this.router.navigate(['parcel-list'], {queryParams: {paquet}});
  }
  pendingList(pending: any) {
    this.router.navigate(['parcel-list'], {queryParams: {pending}});
  }
  arrivedList(arrived: any) {
    this.router.navigate(['parcel-list'], {queryParams: {arrived}});
  }
  sentList(sent: any) {
    this.router.navigate(['parcel-list'], {queryParams: {sent}});
  }
  receivedList(received: any) {
    this.router.navigate(['parcel-list'], {queryParams: {received}});
  }
  
parcelScan() {
this.router.navigate(['scan'])
}
parcelList() {
this.router.navigate(["parcel-list"])
}
  parcelData?: any[] = [];
  userId?: string;
  countrySummary: any[] = [];
  monthlySummary: any[] = [];
  dailySummary: any[] = [];
  deliveryRates: any[] = [];
  courierCount = 0;
  courierTotalCost = 0;
  packageCount = 0;
  packageTotalCost = 0;
  totalCount = 0;
  totalCost = 0;
  deliveredCount = 0;
  pendingCount = 0;
  arrivedCount = 0;
  receivedCount = 0;
  sentCount = 0;
  deliveredTotalCost = 0;
  monthlyData: any[] = [];
  
  constructor(private firestore: AngularFirestore,
    private authService: AuthService,
    private router: Router
  ) {}
  ngOnInit(): void {
    this.authService.getUser().subscribe(user => {
      if (user) {
        this.userId = user.uid;
        this.authService.getUserProfile(user.uid).then(profile => {
          this.userProfile = profile;
        });
        this.fetchParcelData();
        console.log("User: ", this.userId);
        console.log("Stored Parcel Data : ", this.parcelData);
        console.log("Parcels number : ", (this.parcelData)?.length);
      }
    });

    this.firestore.collection('parcel', ref => ref.where('status', '!=', "draft")).valueChanges()
      .subscribe((data: any[]) => {
        this.parcelData = data;
        this.monthlyData = this.getLastTwelveMonths(this.parcelData);
      });
  }

  fetchParcelData() {
    this.firestore.collection('parcel', ref => ref.where('status', '!=', "draft"))
    .valueChanges()
    .subscribe((data: any[]) => {
      this.parcelData = data;
      console.log(data);
      console.log("Pacels : ", this.parcelData )
      this.calculateMetrics(this.parcelData);
      
    });

    console.log("Pacels : ", this.parcelData )
  }

  calculateMetrics(parcels: any[]): void {
    // Example of counting parcels by type
    this.countrySummary = this.getTopCountries(parcels);
    this.monthlySummary = this.getMonthlySummary(parcels);
    this.dailySummary = this.getDailySummary(parcels);
    this.deliveryRates = this.getDeliveryRates(parcels);
    this.courierCount = this.getTypeCount(parcels, 'Courrier');
    this.packageCount = this.getTypeCount(parcels, 'Paquet');
    this.totalCount = parcels.filter(p => p.status !== 'draft').length;
    this.deliveredCount = parcels.filter(p => p.status === 'delivered').length;
    this.pendingCount = parcels.filter(p => p.status === 'pending').length;
    this.arrivedCount = parcels.filter(p => p.status === 'arrived').length;
    this.receivedCount = parcels.filter(p => p.status === 'received').length;
    this.sentCount = parcels.filter(p => p.status === 'sent').length;
    this.courierTotalCost = this.getTypeTotalCost(parcels, 'Courrier');
    this.packageTotalCost = this.getTypeTotalCost(parcels, 'Paquet');
    this.totalCost = this.getTotalCost(parcels);
    this.deliveredTotalCost = this.getDeliveredTotalCost(parcels);
  }


  // Helpers for calculations
  getTopCountries(parcels: any[]): any[] {
    const countryMap = new Map();
    parcels.forEach(parcel => {
      const country = parcel.phonePrefix2 || 'Others';
      const cost = parcel.cost || 0;
      if (!countryMap.has(country)) {
        countryMap.set(country, { commandCount: 0, totalCost: 0 });
      }
      countryMap.get(country).commandCount++;
      countryMap.get(country).totalCost += cost;
      // console.log("Country Map : ", countryMap);
    });

    let countries = Array.from(countryMap, ([name, value]) => ({ name, ...value }));
    countries = countries.sort((a, b) => b.totalCost - a.totalCost);

    // Get top 5 countries and combine the rest under 'Others'
    const top5 = countries.slice(0, 5);
    const others = countries.slice(5).reduce((acc, cur) => {
      acc.commandCount += cur.commandCount;
      acc.totalCost += cur.totalCost;
      return acc;
    }, { name: 'Autres', commandCount: 0, totalCost: 0 });
    
    return [...top5, others];
  }

  getMonthlySummary(parcels: any[]): any[] {
    if (parcels.length === 0) return [];
     // Step 1: Get the latest create_date and determine the current year
  // const latestDate = new Date(Math.max(...parcels.map(p => new Date(p.command_date).getTime())));
  const latestDate = new Date(parcels.reduce((latest, parcel) => {
    return parcel.create_date > latest ? parcel.create_date : latest;
  }, parcels[0].create_date));
  console.log("Latest date", latestDate);
  const currentYear = latestDate.getFullYear();
  console.log("Last year : ", currentYear);

  // Step 2: Filter parcels by current year
  const currentYearParcels = parcels.filter(p => new Date(p.create_date).getFullYear() === currentYear && p.status !== 'draft');
  console.log("Number of parcels : ",currentYearParcels.length);
  // Step 3: Initialize an array for monthly totals
  const monthlyTotals = Array(12).fill(0); // One entry for each month (Jan to Dec)

  // Step 4: Calculate total cost for the current year
  const totalYearCost = currentYearParcels.reduce((acc, cur) => acc + (typeof cur.cost === 'number' && !isNaN(cur.cost) ? cur.cost : 0), 0);

  // Step 5: Group parcels by month and calculate totals for each month
  currentYearParcels.forEach(parcel => {
    const parcelDate = new Date(parcel.create_date); // Parse the create_date string to a Date object
    const month = parcelDate.getMonth(); // getMonth() returns 0 for January, 11 for December
    monthlyTotals[month] += (typeof parcel.cost === 'number' && !isNaN(parcel.cost)) ? parcel.cost : 0;
  });

  // Step 6: Calculate the percentage for each month compared to the total yearly cost
  const monthlySummary = monthlyTotals.map((total, month) => ({
    month: this.getMonthName(month),
    totalCost: total,
    percentage: totalYearCost > 0 ? (total / totalYearCost) * 100 : 0
  }));
  console.log("Months : ", monthlySummary);
  return monthlySummary;
  }
  getMonthName(monthIndex: number): string {
    const monthNames = ['Jan', 'Fév', 'Mar', 'Avl', 'Mai', 'Juin', 'Juil', 'Aout', 'Sep', 'Oct', 'Nov', 'Dec'];
    return monthNames[monthIndex];
  }

  getDailySummary(parcels: any[]): any[] {
    // Logic for daily summary goes here
    return [];
  }

  getDeliveryRates(parcels: any[]): any[] {
    // Logic for delivery rates goes here
    return [];
  }

  getTypeCount(parcels: any[], type: string): number {
    return parcels.filter(p => p.type === type && p.status !== 'draft').length;
  }

  getTypeTotalCost(parcels: any[], type: string): number {
    return parcels.filter(p => p.type === type && p.status !== 'draft')
                  .reduce((acc, cur) => acc + cur.cost, 0);
  }

  // getTotalCost(parcels: any[]): number {
  //   return parcels.filter(p => p.status !== 'draft')
  //                 .reduce((acc, cur) => acc + cur.cost, 0);
  // }

  getTotalCost(parcels: any[]): number { 
    return parcels
      .filter(p => p.status !== 'draft')
      .reduce((acc, cur) => acc + (typeof cur.cost === 'number' && !isNaN(cur.cost) ? cur.cost : 0), 0);
  }

  getDeliveredTotalCost(parcels: any[]): number {
    return parcels.filter(p => p.status === 'delivered')
                  .reduce((acc, cur) => acc + cur.cost, 0);
  }

  getLastTwelveMonths(parcels: any[]): any[] {
    const today = new Date();
    const months: any[] = [];
    
    // Create array for last 12 months
    for (let i = 0; i < 12; i++) {
      const monthDate = new Date(today.getFullYear(), today.getMonth() - i, 1);
      const monthKey = monthDate.getFullYear() + '-' + (monthDate.getMonth() + 1).toString().padStart(2, '0');
      const monthLabel = this.getMonthName(monthDate.getMonth()) + ' ' + monthDate.getFullYear();
      
      months.push({
        monthKey,
        label: monthLabel,
        totalCost: 0
      });
    }
    
    // Calculate total cost for each month
    parcels.forEach(parcel => {
      if (parcel.create_date) {
        const createDate = new Date(parcel.create_date);
        const monthKey = createDate.getFullYear() + '-' + (createDate.getMonth() + 1).toString().padStart(2, '0');
        
        const monthData = months.find(m => m.monthKey === monthKey);
        if (monthData) {
          monthData.totalCost += Number(parcel.cost || 0);
        }
      }
    });
    
    // Calculate maximum value for scaling
    const maxValue = Math.max(...months.map(month => month.totalCost));
    
    // Create scale markers
    const step = Math.ceil(maxValue / 4 / 100) * 100; // Round to nearest 100
    this.scaleMarkers = [];
    for (let i = 1; i <= 4; i++) {
      this.scaleMarkers.push(step * i);
    }
    
    // Calculate percentage with minimum bar size for zero values
    months.forEach(month => {
      // Give zero values a minimal bar (5%)
      month.percentage = month.totalCost === 0 ? 5 : 
                        5 + ((month.totalCost / maxValue) * 95);
    });
    
    // Return in reverse chronological order (most recent first)
    return months.reverse();
  }

  getBarGradient(index: number, total: number): string {
    // Create gradient from our color scheme based on position
    const colors = ['#003d89', '#248ac1', '#35bcbf', '#1e7684', '#54ddb2'];
    const position = index / (total - 1); // 0 for first, 1 for last
    
    // Recent months get more blue shades, older months get more teal/green
    if (position < 0.25) {
      return `linear-gradient(90deg, ${colors[0]} 0%, ${colors[1]} 100%)`;
    } else if (position < 0.5) {
      return `linear-gradient(90deg, ${colors[1]} 0%, ${colors[2]} 100%)`;
    } else if (position < 0.75) {
      return `linear-gradient(90deg, ${colors[2]} 0%, ${colors[3]} 100%)`;
    } else {
      return `linear-gradient(90deg, ${colors[3]} 0%, ${colors[4]} 100%)`;
    }
  }
}
