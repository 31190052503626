import { Component, OnInit, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { UserProfile } from '../models/user-profile';
import { PaymentService } from '../payment.service';
import { loadStripe } from '@stripe/stripe-js';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { environment } from '../environment';
import { ReceiverService } from '../receiver.service';
import { Receiver } from '../models/receiver';
import { Subject } from 'rxjs';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-parcel-destination',
  templateUrl: './parcel-destination.component.html',
  styleUrl: './parcel-destination.component.css'
})
export class ParcelDestinationComponent implements OnInit {
  private stripePromise = loadStripe('pk_live_51PjczsRo141DzeBj2m3BxL6Cgdia4NTaT7eUsYLGArUg2tvaHVejpDLzixAksSnWXuRTKAAvXIXl1VDurThCKW8p00neC7Y1PI');
  user!: null;
  userProfile?: UserProfile | null = null;
  phoneNumUser?: string | null;
  parcelForm!: FormGroup;
  isMobile: boolean = false;
  cost: number = 0;
  normal_cost: number = 0;
  isLoading = false;
  cities = environment.appConfig.cities;
  parcel_type = environment.appConfig.parcelTypes;
  phonePrefixes = environment.phonePrefixes;
  lAdress!: string;
  totalWeight: string = "";
  errorMessage = "";
  parcelId!: string | null;
  receivers: Receiver[] = [];
  filteredReceivers: Receiver[] = [];
  showReceiverAutocomplete = false;
  private destroy$ = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private firestore: AngularFirestore,
    private authService: AuthService,
    private paymentService: PaymentService,
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private receiverService: ReceiverService) {
    this.parcelForm = this.fb.group({
      type: ["", Validators.required],
      city: ["", Validators.required],
      receiver_name: ["", Validators.required],
      phonePrefix1: ['', Validators.required],
      phonePrefix2: ['', Validators.required],
      receiver_phone: ['', Validators.required],
      sender_phone: ['', Validators.required],
      address: [""],
      sender_name: [""],
      pickupMethod: ['', Validators.required],
      items: this.fb.array([this.createItem()]),
      weight: [1.0],
      condition: [false, Validators.requiredTrue]
    });
  }

  ngOnInit(): void {
//Tell which environment started
    console.log("Component started with environment variables : ", environment);
    this.checkScreenSize();
    this.authService.getUser().subscribe(user => {
      if (user) {
        this.authService.getUserProfile(user.uid).then(profile => {
          this.userProfile = profile;
          console.log(this.userProfile?.fullname);
          this.phoneNumUser = this.userProfile?.phoneNumber;
          console.log(this.userProfile?.phoneNumber);
          this.parcelForm.patchValue({
            sender_name: this.userProfile?.fullname,
            sender_phone: this.userProfile?.phoneNumber,
            phonePrefix2: this.userProfile?.country
          });
        });

        this.receiverService.getReceivers(user.uid)
          .pipe(takeUntil(this.destroy$))
          .subscribe(receivers => {
            this.receivers = receivers;
          });
      }
    });

    this.parcelForm.get('type')?.valueChanges.subscribe(value => {
      this.onTypeChange(value);
    });

    this.parcelForm.get('receiver_name')?.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(300),
        distinctUntilChanged()
      )
      .subscribe(value => {
        if (value && typeof value === 'string' && value.length > 1) {
          this.filterReceivers(value);
          this.showReceiverAutocomplete = true;
        } else {
          this.showReceiverAutocomplete = false;
        }
      });

    this.parcelId = this.route.snapshot.queryParams['parcelID'] || null;
    const restoreData = this.route.snapshot.queryParams['restore'] || null;
    if (restoreData) {
      console.log("Restoring form data from local storage");
      this.restoreFormData();
    } else if (this.parcelId) {
      console.log("Parcel document to restore: ", this.parcelId);
    } else {
      console.log("No data to restore");
    }
  }

  createItem(): FormGroup {
    return this.fb.group({
      itemDescription: [''],
      itemValue: [''],
      numberOfItems: ['']
    });
  }

  addItem(): void {
    this.items.push(this.createItem());
  }

  removeItem(index: number): void {
    this.items.removeAt(index);
  }

  get items(): FormArray {
    return this.parcelForm.get('items') as FormArray;
  }

  saveFormData(): void {
    localStorage.setItem('parcelFormData', JSON.stringify(this.parcelForm.value));
    console.log("Data stored : ", localStorage.getItem('parcelFormData'));
  }

  restoreFormData(): void {
    const data: any = localStorage.getItem('parcelFormData');
    if (data) {
      // The method works after the user creation or authentication
      const parsedData = JSON.parse(data);
      this.parcelForm.patchValue({
        type: parsedData.type,
        city: parsedData.city,
        receiver_name: parsedData.receiver_name,
        phonePrefix1: parsedData.phonePrefix1,
        phonePrefix2: parsedData.phonePrefix2 || this.userProfile?.country,
        receiver_phone: parsedData.receiver_phone,
        sender_phone: parsedData.sender_phone || this.userProfile?.phoneNumber,
        address: parsedData.address || "",
        sender_name: parsedData.sender_name,
        pickupMethod: parsedData.pickupMethod,
        description: parsedData.description,
        weight: parsedData.weight,
        condition: parsedData.condition,
        cost: parsedData.cost,
        // uid: parsedData.uid,
        // status: parsedData.status
      });
      this.items.clear();
      if (!(parsedData.type === "Courrier")) {
        parsedData.items.forEach((item: any) => {
          this.items.push(this.fb.group({
            itemDescription: [item.itemDescription, Validators.required],
            itemValue: [item.itemValue, Validators.required],
            numberOfItems: [item.numberOfItems, Validators.required]
          }));
        });
      }
      this.calculateCost();
      localStorage.removeItem('parcelFormData');
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenSize();
  }

  checkScreenSize() {
    this.isMobile = window.innerWidth <= 768;
  }

  calculateCost() {
    const pickupMethod = this.parcelForm.get('pickupMethod')?.value;
    const weight = this.parcelForm.get("weight")?.value;
    const lcity = this.parcelForm.get("city")?.value;
    const type = this.parcelForm.get("type")?.value;

    if (type === "Courrier") {
      this.normal_cost = environment.appConfig.normalCost.courrier;
      this.cost = environment.appConfig.baseCost.courrier;
      this.totalWeight = "0-1";
      if (pickupMethod === "home_delivery") {
        this.cost += environment.appConfig.homeDeliveryFee;
        this.normal_cost += environment.appConfig.homeDeliveryFee;
      }
    } else if (type === "Paquet") {
      if (weight <= 1) {
        this.totalWeight = "0-1";
      } else {
        this.totalWeight = weight.toString();
      }
      let the_normal_cost = 0.0;
      let theCost = 0.0;
      if (lcity === 'kinshasa') {
        theCost += environment.appConfig.baseCost.paquet.kinshasa;
        the_normal_cost += environment.appConfig.normalCost.paquet.kinshasa;
      } else if (lcity === "lubumbashi") {
        theCost += environment.appConfig.baseCost.paquet.lubumbashi;
        the_normal_cost += environment.appConfig.normalCost.paquet.lubumbashi;
      }
      if (weight <= 1) {
        this.cost = theCost;
        this.normal_cost = the_normal_cost;
      } else {
        this.cost = theCost * weight;
        this.normal_cost = the_normal_cost * weight;
      }
      if (pickupMethod === "home_delivery") {
        this.cost += environment.appConfig.homeDeliveryFee;
        this.normal_cost += environment.appConfig.homeDeliveryFee;
      }
    }
  }

  onTypeChange(type: string): void {
    const weightControl = this.parcelForm.get('weight');
    if (type === 'Courrier') {
      weightControl?.clearValidators();
    } else {
      weightControl?.setValidators([Validators.required]);
    }
    weightControl?.updateValueAndValidity();
    this.calculateCost();
  }

  filterReceivers(value: string): void {
    const filterValue = value.toLowerCase();
    this.filteredReceivers = this.receivers.filter(receiver =>
      receiver.fullname.toLowerCase().includes(filterValue)
    );
  }

  selectReceiver(receiver: Receiver): void {
    this.parcelForm.patchValue({
      receiver_name: receiver.fullname,
      phonePrefix1: receiver.country,
      receiver_phone: receiver.phoneNumber
    });
    this.showReceiverAutocomplete = false;
  }

  saveReceiver(userId: string): void {
    const receiverName = this.parcelForm.get('receiver_name')?.value;
    const receiverPhone = this.parcelForm.get('receiver_phone')?.value;
    const receiverCountry = this.parcelForm.get('phonePrefix1')?.value;

    if (userId && receiverName && receiverPhone && receiverCountry) {
      this.receiverService.checkReceiverExists(userId, receiverName, receiverPhone)
        .subscribe(exists => {
          if (!exists) {
            const newReceiver: Receiver = {
              fullname: receiverName,
              phoneNumber: receiverPhone,
              country: receiverCountry,
              userId: userId
            };

            this.receiverService.addReceiver(newReceiver)
              .then(() => console.log('Receiver saved successfully'))
              .catch(error => console.error('Error saving receiver:', error));
          }
        });
    }
  }

  async onSubmit() {
    console.log("Form data: ", this.parcelForm.value);
    if (this.parcelForm.valid) {
      try {
        const parcelData = this.parcelForm.value;
        parcelData.status = 'draft';
        const user = this.authService.user;
        if (user) {
          this.isLoading = true;
          console.log("User already authenticated");
          parcelData.uid = user.uid;
          parcelData.status = 'draft';
          parcelData.cost = this.cost;
          const parcelRef = await this.firestore.collection('parcel').add(parcelData);
          const parcelId = parcelRef.id;
          const country = parcelData.phonePrefix2;
          const sender_phone = parcelData.sender_phone;
          const sender_name = parcelData.sender_name;
          const notificationNumber = this.phonePrefixes.find(prefix => prefix.label === country)?.value + sender_phone;
          console.log("Here are the sender informations : ", sender_name, ", country : ", country, ", phone : ", sender_phone);
          console.log("The notification should be sebt to : ", notificationNumber);
          console.log("Parcel Document: ", parcelId);
          const itemsArray = this.items.controls; // FormArray of items
          for (const itemControl of itemsArray) {
            const itemID = this.firestore.createId();
            await this.firestore.collection('items').doc(itemID).set({
              ...itemControl.value,
              parcelID: parcelId,
              uid: user.uid
            });
          }
          const realAmount = this.cost;

          try {
            const registrationResponse = await firstValueFrom(
              this.http.post<{ sessionId: string, parcel: string, trackingID: string }>(
                environment.apiUrls.createInvoice,
                { email: user.email, parcel: parcelId, amount: realAmount, phoneNumber: notificationNumber, sender: sender_name, pType: parcelData.type }
              )
            );

            const stripe = await this.stripePromise;
            if (stripe) {
              stripe.redirectToCheckout({ sessionId: registrationResponse.sessionId });
            }
          } catch (error) {
            console.error('Error during parcel registration process:', error);
          } finally {
            this.isLoading = false;
          }

          this.saveReceiver(user.uid);
        } else {
          console.error("User not yet authenticated");
          this.saveFormData();
          this.router.navigate(['/auth'], { queryParams: { returnUrl: '/destination', restore: true } });
        }
      } catch (error) {
        console.error("Error saving parcel", error);
      }
    } else {
      console.log('Form is invalid', this.parcelForm.errors);
      this.logFormErrors(this.parcelForm);
      this.errorMessage = "Veuillez remplir les champs obligatoires marqués d'un *";
    }
  }

  logFormErrors(group: FormGroup | FormArray): void {
    Object.keys(group.controls).forEach((key: string) => {
      const control = group.get(key);
      if (control instanceof FormGroup || control instanceof FormArray) {
        this.logFormErrors(control);
      } else {
        if (control?.invalid) {
          console.log('Invalid control:', key, control.errors);
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
