import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslationService } from '../translate.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.css'
})
export class FooterComponent {
  currentLanguage: string;

  constructor(private translationService: TranslationService) {
    this.currentLanguage = localStorage.getItem('appLanguage') || 'fr';
  }

  setLanguage(language: string) {
    this.translationService.changeLanguage(language);
    this.currentLanguage = language;
    window.location.reload();
  }
}

