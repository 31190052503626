import { Component, OnInit } from '@angular/core';
import { UserProfile } from '../models/user-profile';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AuthService } from '../auth.service';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../environment';
@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrl: './contactus.component.css'
})
export class ContactusComponent implements OnInit{
  phonePrefixes = environment.phonePrefixes; // Assuming you have a list of phone prefixes in your environment file  
  userProfile: UserProfile | null = null;
  contactForm!: FormGroup;
  errorMessage: string="";
  constructor(
    private fb: FormBuilder,
    private firestore : AngularFirestore,
    private authService : AuthService,
    private router : Router
  ){}

  ngOnInit(): void {
    this.authService.getUser().subscribe(user => {
      if (user) {
        this.authService.getUserProfile(user.uid).then(profile => {
          this.userProfile = profile;
          console.log(this.userProfile?.fullname);
        });
      }
    });
    this.contactForm = this.fb.group({
      contact_sender_name: ["", Validators.required],
      prefix: [""],
      phonePrefix2: [""],
      phoneNumber: ["", Validators.required],
      email: ["", Validators.email],
      subject: ["", Validators.required],
      question: ["", Validators.required]
    });
    
  }
  async onSubmit(){
    if (this.contactForm.valid){
      try{
        const date = new Date().toISOString();
      const messageData = this.contactForm.value;
      messageData.status = 'unread';
      if (this.userProfile){
        messageData.uid = this.userProfile.uid;
        messageData.client = this.userProfile;
      }
      messageData.submit_date = date;
      messageData.display_submit_date = date.split('T')[0];
      const messagesRef = await this.firestore.collection('messages').add(messageData);
      const messageID = messagesRef.id;
      console.log("Message registered : ", messageID);
      this.router.navigate(['sucess-message']);
      }catch(error){
        console.error("Error when sending message : ", error);
        this.errorMessage = "Problème de connexion au serveur : "+ error;
      }
      
    }else {
      console.log('Form is invalid', this.contactForm.errors);
      this.logFormErrors(this.contactForm);
      this.errorMessage = "Veuillez remplir les champs obligatoires marqués d'un *"
    }
    
  }
  logFormErrors(group: FormGroup | FormArray): void {
    Object.keys(group.controls).forEach((key: string) => {
      const control = group.get(key);
      if (control instanceof FormGroup || control instanceof FormArray) {
        this.logFormErrors(control);
      } else {
        if (control?.invalid) {
          console.log('Invalid control:', key, control.errors);
        }
      }
    });
  }
}
